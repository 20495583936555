import { createRouter, createWebHistory } from 'vue-router';
import LandingPage from '@/pages/LandingPage';
import NotFound from '@/pages/NotFound';
import OpeningPrompt from '@/pages/OpeningPrompt.vue';
import PathogenesisPage from '@/pages/PathogenesisPage.vue';
import MonitoringPage from '@/pages/MonitoringPage.vue';
import ResourcesPage from '@/pages/ResourcesPage.vue';
import ManagementPage from '@/pages/ManagementPage.vue';

function hasAccessCookie() {
    return document.cookie.split('; ').find(row => row.startsWith('userAccess='))?.split('=')[1] === 'granted';
}

const baseRoutes = [
    {
        path: '/',
        name: 'OpeningPrompt',
        component: OpeningPrompt,
        meta: {
            hideHeader: true,
            hideFooter: true,
        }
    },
    {
        path: '/home',
        name: 'LandingPage',
        component: LandingPage,
    },
    {
        path: '/pathogenesis',
        name: 'PathogenesisPage',
        component: PathogenesisPage,
    },
    {
        path: '/monitoring',
        name: 'MonitoringPage',
        component: MonitoringPage,
    },
    {
        path: '/management',
        name: 'ManagementPage',
        component: ManagementPage,
    },
    {
        path: '/resources',
        name: 'ResourcesPage',
        component: ResourcesPage,
    },
    {
        path: '/:catchAll(.*)',
        name: 'Not Found',
        component: NotFound,
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes: baseRoutes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth'
            };
        }
        return { top: 0 };
    }
});

// global route block 
router.beforeEach((to, from, next) => {
    if (to.path !== '/' && !hasAccessCookie()) {
        next('/');
    } else {
        next();
    }
});

export default router;
