<template>
    <div class="wrapper">
        <metainfo></metainfo>

        <IconSet />

        <PageHeader v-if="!route.meta?.hideHeader" />

        <main class="main ">
            <router-view />
        </main>

        <PageFooter v-if="!route.meta?.hideFooter" />
    </div>
</template>

<script setup>
import IconSet from '@/components/IconSet.vue';
import PageHeader from '@/components/PageHeader.vue';
import PageFooter from '@/components/PageFooter.vue';
import { useMeta } from 'vue-meta';
import { useRoute } from 'vue-router';

useMeta({
    title: 'EBV+ PTLD',
});

const route = useRoute();
</script>

<style lang="scss">
// @import './src/scss/app';
</style>
