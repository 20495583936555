<template>
    <div v-if="!hasAccess" class="h-screen min-h-screen w-full flex flex-col justify-center items-center bg-blue">
        <div class="mb-12 text-center">
            <h1 class="text-4xl font-semibold text-white leading-normal">
                The information on this website is intended <br />for US <span class="text-green">healthcare professionals</span>.
            </h1>
        </div>

        <div class="w-full max-w-4xl flex flex-col gap-8 text-white px-5">
            <button class="p-3 text-base font-bold rounded-xl border border-white flex items-center justify-center hover:bg-darkblue" @click="grantAccess">
                <span>I am a US healthcare professional</span><img
                    src="@/assets/img/Arrow_Right.svg"
                    alt="Arrow Right icon"
                    class="ml-2 inline-block"
                />
            </button>
            <button class="p-3 text-base font-bold rounded-xl border border-white flex items-center justify-center hover:bg-darkblue" @click="denyAccess">
                <span>I am not a US healthcare professional</span><img
                    src="@/assets/img/Arrow_Right.svg"
                    alt="Arrow Right icon"
                    class="ml-2 inline-block"
                />
            </button>
        </div>
    </div>

    <div v-else>
        <router-view></router-view>
    </div>
</template>

  
<script>
export default {
    data() {
        return {
            hasAccess: false
        };
    },
    created() {

        this.hasAccess = this.checkCookie();
        if (this.hasAccess) {

            this.$router.push('/home');
        } else {

            this.$router.push('/');
        }
    },
    methods: {
        grantAccess() {
            // cookie set to 24 hours
            document.cookie = 'userAccess=granted; max-age=' + 24 * 60 * 60;
            this.hasAccess = true;
            this.$router.push('/home');
        },
        denyAccess() {
            window.location.href = 'https://www.pierrefabrepharmaceuticals.com/';
        },
        checkCookie() {

            return document.cookie.split('; ').find(row => row.startsWith('userAccess='))?.split('=')[1] === 'granted';
        }
    }
};
</script>
  
  <style scoped>
  </style>
  