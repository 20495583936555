<template>
    <div>
        <div
            v-if="isMobile"
            :class="['flex flex-col justify-center', isMobile ? 'gradient-bg' : 'bg-cover bg-no-repeat bg-left-bottom']"
            :style="!isMobile ? { backgroundImage: `url(${bg1})` } : {}"
        >
            <div class="container mx-auto flex flex-col items-center px-6 sm:px-12 md:px-96">
                <div class="p-6 md:p-8 flex flex-col text-darkblue mt-16 text-center">
                    <h1 class="text-4xl md:text-4xl font-semibold mb-4">
                        Identifying patients with Epstein-Barr virus positive post-transplant lymphoproliferative disease (EBV<span class="text-green">+</span> PTLD)
                    </h1>
                    <p class="text-base font-light md:text-xl">
                        The development of EBV+ PTLD can be an unexpected shock to transplant patients, who may have expected to reclaim their lives from illness after transplant. It is important clinicians know how to identify at-risk patients as well as the signs and symptoms of EBV+ PTLD in order to achieve an early diagnosis and reduce disease burden
                    </p>
                </div>
                <img
                    src="@/assets/img/falling_blocks.png"
                    alt="Scroll Arrow"
                    class="w-full mt-12"
                />
            </div>
            
            <div class="mb-12">
                <div class="flex w-full justify-center items-center text-center" @click="scrollToSection('section2')">
                    <img
                        src="@/assets/img/down-arrow.svg"
                        alt="Scroll Arrow"
                        class="ml-2 w-12 pl-0"
                    />
                </div>
            </div>
        </div>

        <div
            v-if="!isMobile"
            class="hero-section flex items-center justify-center text-darkblue"
            :style="{ backgroundImage: `url(${bg1})` }"
        >
            <div class="text-content">
                <h1 class="hero-h1">
                    Identifying patients with <br />Epstein-Barr virus positive <br />post-transplant <br />lymphoproliferative disease (EBV<span class="text-green">+</span> PTLD)
                </h1>
                <p class="hero-p">
                    The development of EBV+ PTLD can be an unexpected shock to transplant patients, who may have expected to reclaim their lives from illness after transplant. It is important clinicians know how to identify at-risk patients as well as the signs and symptoms of EBV+ PTLD in order to achieve an early diagnosis and reduce disease burden
                </p>
            </div>
            <img
                src="@/assets/img/down-arrow.svg"
                alt="Scroll Arrow"
                class="home-down-arrow"
                @click="scrollToSection('section2')"
            />
        </div>


        <!-- section 2  -->
        <div id="section2" class="bg-blue w-full py-12 md:py-16 px-6 md:px-6">
            <div class="flex flex-col lg:flex-row container mx-auto items-center">
                <!-- Left Column (50%) -->
                <div class="text-white w-full lg:w-1/2 py-6 lg:p-8">
                    <h1 class="items-center justify-center font-semibold text-center">
                        Key aspects in identifying patients with EBV<span class="text-green">+</span> PTLD
                    </h1>
                </div>

                <!-- Right Column (50%) -->
                <div class="w-full lg:w-1/2 flex flex-col lg:flex-row lg:flex-wrap lg:justify-between">
                    <!-- Top Row with three equal columns -->
                    <div class="flex flex-col lg:flex-row gap-4 md:gap-6 mb-4 w-full">
                        <div class="w-full lg:w-1/3 p-2 rounded-3xl border py-12 px-4" @click="scrollToSection('one')">
                            <h3 class="text-green text-7xl mb-5 font-bigShoulders">
                                1.
                            </h3>
                            <h5 class="text-white lg:mb-20 min-h-12">
                                At risk patients
                            </h5>
                            <h5 class="text-white flex items-center">
                                <span class="hover-underline">Read more </span>
                                <img src="@/assets/img/Arrow_Right.svg" alt="Graph 1" class="ml-2" />
                            </h5>
                        </div>
                        <div class="w-full lg:w-1/3 p-2 rounded-3xl border py-12 px-4" @click="scrollToSection('symptom')">
                            <h3 class="text-green text-7xl mb-5 font-bigShoulders">
                                2.
                            </h3>
                            <h5 class="text-white lg:mb-20 min-h-12">
                                Signs and symptoms
                            </h5>
                            <h5 class="text-white flex items-center">
                                <span class="hover-underline">Read more </span>
                                <img src="@/assets/img/Arrow_Right.svg" alt="Graph 1" class="ml-2" />
                            </h5>
                        </div>
                        <div class="w-full lg:w-1/3 p-2 rounded-3xl border py-12 px-4" @click="scrollToSection('three')">
                            <h3 class="text-green text-7xl mb-5 font-bigShoulders">
                                3.
                            </h3>
                            <h5 class="text-white lg:mb-20 min-h-12">
                                Diagnosis
                            </h5>
                            <h5 class="text-white flex items-center">
                                <span class="hover-underline">Read more </span>
                                <img src="@/assets/img/Arrow_Right.svg" alt="Graph 1" class="ml-2" />
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- section 3 -->
        <div id="one" class="bg-white w-full py-12 md:py-16 px-6 md:px-6">
            <div class="container mx-auto text-center">
                <h2 class="text-4xl font-bold text-darkblue mb-8">
                    Recipients of SOT or HCT have an increased risk of EBV+ PTLD due to impaired T-cell immunity<sup>1</sup>
                </h2>

                <div class="flex flex-col md:flex-row container mx-auto items-center text-darkblue">
                    <!-- Left Column (50%) -->
                    <div class="w-full md:w-1/2 md:pr-24 p-6 md:p-8">
                        <h3 class="text-green text-7xl mb-5 font-bigShoulders">
                            9.1%
                        </h3>
                        <h1 class="mb-2 text-3xl font-semibold lg:text-4xl">
                            SOT recipients
                        </h1>
                        <p class="text-blue lg:text-xl">
                            Incidence up to 9.1% (depending on the organ transplanted)*<sup>2–7</sup>
                        </p>
                        <br />
                        <p class="text-blue lg:text-xl">
                            Lifelong risk of developing PTLD – due to their lifelong requirement for immunosuppression<sup>1</sup>
                        </p>
                        <br />
                        <p class="lg:text-xl">
                            Approximately 50% of cases of PTLD are EBV-associated<sup>8</sup>
                        </p>
                    </div>
  
                    <!-- Right Column (50%) -->
                    <div class="w-full md:w-1/2 flex flex-col justify-between text-darkblue">
                        <h3 class="text-green text-7xl mb-5 font-bigShoulders">
                            1.1 – 1.7%
                        </h3>
                        <h1 class="mb-2 text-3xl font-semibold lg:text-4xl">
                            HCT recipients
                        </h1>
                        <p class="text-blue lg:text-xl">
                            Incidence of 1.1­–1.7% (in the first-year post-transplant)<sup>9,10</sup>
                        </p>
                        <br />
                        <p class="text-blue lg:text-xl">
                            The majority of cases occur within a year post-transplant ­­– due to short-term requirement for immunosuppression<sup>1,11</sup>
                        </p>
                        <br />
                        <p class="lg:text-xl">
                            Almost all cases of PTLD are EBV-associated<sup>8,12,13</sup>
                        </p>
                    </div>
                </div>

                <p class="text-xs pt-5">
                    *PTLD 5-year cumulative incidence by organ: Kidney 0.6%, heart 0.9%, liver 1%,  lung 1.7%, pancreas 2.5%, intestine 9.1%.<sup>2–7</sup>
                </p>

                <!-- <div class="bg-green text-white rounded-lg py-5 px-4 flex flex-col md:flex-row items-center text-center md:text-left mt-5">
                    <div class="flex justify-center md:justify-start mb-4 md:mb-0">
                        <img src="@/assets/img/quote-icon.svg" alt="Icon" class="w-16 h-16" />
                    </div>
                    <div class="font-bigShoulders text-center">
                        <h1 class="text-3xl font-normal md:px-5">
                            *PTLD 5-year cumulative incidence by organ: Kidney 0.6%, liver 1%, heart 0.9%, lung 1.7%, pancreas 2.5%, intestine 9.1%.<sup>2–7</sup>
                        </h1>
                        <p class="text-sm">
                            Dr Ran Reshef, sourced from agence-sparadrap.com/ebvptld/
                        </p>
                    </div>
                </div> -->
            </div>
        </div>

        <!-- section 2  -->
        <div class="gradient-custom w-full py-12 md:py-16 px-6 md:px-6">
            <div class="container flex flex-col md:flex-row mx-auto items-center justify-center">
                <!-- Left Column (50%) -->
                <div class="flex-1 p-6 text-center flex items-center justify-center">
                    <img
                        src="@/assets/img/warning.svg"
                        alt="Graph 1"
                        class="h-full"
                    />
                </div>
  
                <!-- Right Column (50%) -->
                <div class="flex-1 p-6 text-darkblue">
                    <h1 class="text-center leading-tight mb-5 font-semibold">
                        Every transplant patient should be evaluated for their risk of developing EBV<span class="text-green">+</span> PTLD
                    </h1>
                    <p class="text-blue lg:text-xl">
                        History of prior EBV infection of the donor and recipient forms the basis for risk assessment for post-transplant infection. Thus, EBV serologic screening is strongly recommended for all HCT and SOT donors and recipients<sup>11,14–16</sup>
                    </p>
                </div>
            </div>

            <div class="flex flex-col md:flex-row container mx-auto items-center justify-center text-darkblue">
                <!-- Single Accordion -->
                <div class="w-full">
                    <div 
                        class="border border-blue rounded-lg p-5 md:px-12 cursor-pointer text-blue flex justify-between" 
                        @click="toggleAccordion1"
                    >
                        <h1 class="text-lg font-light">
                            The risk of developing EBV+ PTLD post-SOT and post-HCT is increased by patient and transplant characteristics<sup>14–17</sup>
                        </h1>
                        <img
                            src="@/assets/img/chevron-down-blue.svg"
                            alt="Icon"
                            class="w-6 h-6 mr-5"
                            :class="[isAccordion1Open ? 'transform rotate-180' : '']"
                        />
                    </div>
                    <div v-if="isAccordion1Open" class="p-5 md:px-12 mt-2 md:mx-12 text-blue"> 
                        <div class="flex flex-col md:flex-row gap-4 pt-3">
                            <div class="flex-1">
                                <h3 class="font-light mb-8 leading-tight text-center">
                                    Risk factors post-SOT<sup>11,14,15</sup>
                                </h3>
                                <h5 class="font-light mb-8 leading-tight text-green">
                                    Patient characteristics
                                </h5>
                                <ul class="list-disc pl-5 mb-8">
                                    <li>Aged &lt;10 years or >60 years</li>
                                    <li>Genetic factors related to the immune system</li>
                                    <li>EBV mismatch with donor  (donor EBV+/ recipient EBV-)</li>
                                    <li>EBV reactivation &lt;12 months after transplantation</li>
                                </ul>
                                <h5 class="font-light mb-8 leading-tight text-green">
                                    Transplant characteristics
                                </h5>
                                <ul class="list-disc pl-5 mb-8">
                                    <li>Type of organ transplanted:* Multiorgan > intestine > lung > heart > liver > pancreas > kidney</li>
                                    <li>Degree and duration of immunosuppressive therapy</li>
                                    <li>Certain immunosuppressive agents (eg. anti-thymocyte globulin, calcineurin inhibitors) post-transplant</li>
                                </ul>
                            </div>
                            <div class="flex-1">
                                <h3 class="font-light mb-8 leading-tight text-center">
                                    Risk factors post-HCT<sup>14,16</sup>
                                </h3>
                                <h5 class="font-light mb-8 leading-tight text-green">
                                    Patient pre-transplant characteristics
                                </h5>
                                <ul class="list-disc pl-5 mb-8">
                                    <li>Aged &lt;20 or ≥50 years</li>
                                    <li>T-cell depletion (either <em>in vivo</em> or <em>ex vivo</em>)</li>
                                    <li>EBV serology donor/recipient mismatch</li>
                                    <li>Cord blood transplant</li>
                                    <li>HLA mismatch</li>
                                    <li>Splenectomy</li>
                                    <li>>1 allogeneic HCT</li>
                                </ul>
                                <h5 class="font-light mb-8 leading-tight text-green">
                                    Patient post-transplant characteristics
                                </h5>
                                <ul class="list-disc pl-5 mb-8">
                                    <li>Severe acute or chronic GvHD</li>
                                    <li>High or rising EBV viral load</li>
                                    <li>Treatment with mesenchymal stem cells</li>
                                </ul>
                            </div>
                        </div>
                        <p>*PTLD 5-year cumulative incidence by organ: Kidney 0.6%, liver 1%, heart 0.9%, lung 1.7%, pancreas 2.5%, intestine 9.1%.<sup>2–7</sup></p>
                    </div>
                </div>
            </div>
        </div>


        <!-- section 3 -->
        <div id="symptom" class="bg-blue w-full py-12 md:py-16 px-6 md:px-6">
            <div class="container mx-auto text-center text-white">
                <h2 class="text-4xl font-bold mb-8 leading-tight">
                    The signs and symptoms of EBV<span class="text-green">+</span> PTLD are heterogeneous,  yet disease progression is rapid and aggressive<sup>1,8,17,18</sup>
                </h2>

                <p class="mb-8 lg:text-xl">
                    Vigilance for the key signs of EBV+ PTLD is important as an early diagnosis can help optimize treatment outcomes and reduce disease burden. Presentation of EBV+ PTLD ranges from incidental, asymptomatic findings to fulminant presentation, including organ failure and spontaneous tumor lysis.<sup>8</sup>
                </p>

                <div class="flex flex-col md:flex-row container mx-auto items-center mb-12">
                    <!-- Left Column (50%) -->
                    <div class="w-full md:w-1/2 md:pr-24 p-6 md:p-8">
                        <h3 class="text-green text-7xl mb-5 font-bigShoulders">
                            B
                        </h3>
                        <h3 class="mb-2 font-normal">
                            B Symptoms<sup>1,17</sup>
                        </h3>
                        <p>Fever, night sweats, weight loss, lymphadenopathy</p>
                    </div>
  
                    <!-- Right Column (50%) -->
                    <div class="w-full md:w-1/2 flex flex-col justify-between">
                        <h3 class="text-green text-7xl mb-5 font-bigShoulders">
                            Rare
                        </h3>
                        <h3 class="mb-2 font-normal">
                            Rare symptoms<sup>1,17</sup>
                        </h3>
                        <p>Encephalitis/myelitis, pneumonitis, hepatitis, and hemophagocytic lymphohistiocytosis</p>
                    </div>
                </div>

                <p class="mb-8">
                    Although PTLD can be localized in any organ, it most commonly targets the central nervous system, lungs, gastrointestinal tract, lymph nodes and/or liver.<sup>17</sup>
                </p>
            </div>
        </div>

        <!-- section 3 -->
        <div v-if="!isMobile" id="three" class="gradient-bg w-full py-12 md:py-16 px-6 md:px-6">
            <div class="container mx-auto text-center">
                <div class="flex flex-col md:flex-row container mx-auto items-center">
                    <!-- Left Column (50%) -->
                    <div class="w-full md:w-1/2 px-16">
                        <img
                            src="@/assets/img/PF_jenga_cells_individual_side1.png"
                            class="mx-auto"
                        />
                    </div>
  
                    <!-- Right Column (50%) -->
                    <div class="w-full md:w-1/2 flex flex-col justify-between">
                        <h1 class="text-center md:text-left leading-tight mb-5 text-darkblue font-semibold text-4xl">
                            Every transplant patient should be evaluated and monitored for their risk of developing EBV<span class="text-green">+</span> PTLD
                        </h1>
                        <p class="text-blue mb-12 mt-8 text-left lg:text-xl">
                            Diagnosis of EBV+ PTLD is achieved through biopsy alongside detection/monitoring of EBV titer and imaging tests<sup>17,18</sup>
                        </p>
                        <!-- removed per feedback  -->
                        <!-- <div class="bg-green text-white rounded-lg py-5 px-4 flex flex-col gap-4 md:flex-row items-center text-center md:text-left">
                            <div class="flex justify-center md:justify-start md:mb-0">
                                <img src="@/assets/img/quote-icon.svg" alt="Icon" class="w-16 h-16" />
                            </div>
                            <div class="font-bigShoulders text-center">
                                <h1 class="text-3xl font-normal">
                                    Typically the first indicator is EBV <br />viraemia detected by blood levels
                                </h1>
                                <p class="text-sm">
                                    Dr Susan Prockop, sourced from youtube.com/watch?v=988ciylvnm0
                                </p>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>

        <div v-else-if="isMobile" id="three" class="gradient-bg w-full py-12 md:py-16 px-6 md:px-6">
            <div class="container mx-auto text-center">
                <div class="flex flex-col md:flex-row container mx-auto items-center">
                    <!-- Left Column (50%) -->
                    <div class="w-full md:w-1/2 md:px-16">
                        <h1 class="text-center md:text-left leading-tight mb-5 text-darkblue font-semibold text-4xl">
                            Every transplant patient should be evaluated and monitored for their risk of developing EBV+ PTLD
                        </h1>

                        <img
                            src="@/assets/img/PF_jenga_cells_individual_side1.png"
                            class="mx-auto my-5"
                        />
                        <p class="text-blue mb-12 mt-8 text-left">
                            Diagnosis of EBV+ PTLD is achieved through biopsy alongside detection/monitoring of EBV titer and imaging tests<sup>18</sup>
                        </p>

                        

                        <!-- <div class="bg-green text-white rounded-lg py-5 px-4 flex flex-col md:flex-row gap-4 items-center text-center md:text-left">
                            <div class="flex justify-center md:justify-start md:mb-0">
                                <img src="@/assets/img/quote-icon.svg" alt="Icon" class="w-16 h-16" />
                            </div>
                            <div class="font-bigShoulders text-center">
                                <h1 class="text-3xl font-normal">
                                    Typically the first indicator is EBV <br />viraemia detected by blood levels
                                </h1>
                                <p class="text-sm">
                                    Dr Susan Prockop, sourced from youtube.com/watch?v=988ciylvnm0
                                </p>
                            </div>
                        </div> -->
                    </div>
  
                    <!-- Right Column (50%) -->
                    <div class="w-full md:w-1/2 flex flex-col justify-between pr-40">
                    </div>
                </div>
            </div>
        </div>

        <!-- section 3 -->
        <div class="bg-white w-full py-12 md:py-16 px-6 md:px-6 text-blue">
            <div class="container mx-auto text-center">
                <h2 class="text-4xl font-semibold mb-8 text-blue">
                    Monitoring EBV viral load
                </h2>

                <div class="flex flex-col md:flex-row container mx-auto items-center mb-12">
                    <!-- Left Column (50%) -->
                    <div class="w-full md:w-1/2 md:pr-24 p-6 md:p-8 text-blue">
                        <h3 class="text-green text-7xl mb-5 font-bigShoulders">
                            SOT
                        </h3>
                        <p class="lg:text-xl">
                            SOT patients who are EBV negative at the time of transplant are at high risk of developing EBV+ PTLD and should be monitored frequently for changes in EBV viral load<sup>19</sup>
                        </p><br />
                        <p class="lg:text-xl">
                            Frequent EBV viral load monitoring is most useful in SOT recipients who are EBV negative pretransplant and possibly in all intestinal transplant recipients<sup>19</sup>
                        </p>
                    </div>
  
                    <!-- Right Column (50%) -->
                    <div class="w-full md:w-1/2 flex flex-col justify-between text-blue">
                        <h3 class="text-green text-7xl mb-5 font-bigShoulders">
                            HCT
                        </h3>
                        <p class="lg:text-xl">
                            All HCT patients should be monitored for EBV+ PTLD development, with more frequent monitoring in patients with rising EBV DNAemia<sup>20</sup>
                        </p><br />
                        <p class="lg:text-xl">
                            HCT patients should be monitored for EBV DNAemia within the first month after an allogeneic HCT, and monitoring should continue for at least 4 months, with a frequency of at least once per week<sup>20</sup>
                        </p>
                    </div>
                </div>
                <!-- ACCORDION SECTION START   -->

                <!-- ACCORDION 1 -->
                <div class="mb-6">
                    <div 
                        class="border border-blue rounded-lg p-5 md:px-12 cursor-pointer text-blue flex justify-between" 
                        @click="toggleAccordion2"
                    >
                        <h1 class="text-lg font-light">
                            EBV+ PTLD can develop across a wide range of EBV viral loads<sup>16</sup>
                        </h1>
                        <img
                            src="@/assets/img/chevron-down-blue.svg"
                            alt="Icon"
                            class="w-6 h-6 mr-5"
                            :class="[isAccordion2Open ? 'transform rotate-180' : '']"
                        />
                    </div>
     
                    <div v-if="isAccordion2Open" class="p-4 md:px-12"> 
                        <p class="text-left font-md lg:font-xl">
                            While a higher or rapidly increasing viral load is associated with an increased risk of EBV+ PTLD, EBV viral load can vary widely at the time of clinical presentation<sup>16</sup>
                        </p>
                    </div>
                </div>

                <!-- ACCORDION 2  -->
                <div class="mb-6">
                    <div 
                        class="border border-blue rounded-lg p-5 md:px-12 cursor-pointer text-blue flex justify-between" 
                        @click="toggleAccordion3"
                    >
                        <h1 class="text-lg font-light">
                            No official staging system currently exists for EBV+ PTLD, however a possible staging system is available<sup>17</sup>
                        </h1>
                        <img
                            src="@/assets/img/chevron-down-blue.svg"
                            alt="Icon"
                            class="w-6 h-6 mr-5"
                            :class="[isAccordion3Open ? 'transform rotate-180' : '']"
                        />
                    </div>
     
                    <div v-if="isAccordion3Open" class="p-5 md:px-12">
                        <div class="bg-blue rounded-t-lg">
                            <h4 class="py-3 text-2xl text-white font-bigShoulders">
                                Possible staging of PTLD<sup>17</sup>
                            </h4>
                        </div>

                        <div class="flex flex-col md:flex-row py-3">
                            <p class="flex-1 px-4 py-3 border-b border-gray-200 md:border-r md:border-b-0">
                                <span class="font-bold">Clinical end-organ staging:</span><br />nodal vs. extra nodal disease
                            </p>

                            <p class="flex-1 px-4 py-3 border-b border-gray-200 md:border-r md:border-b-0">
                                <span class="font-bold">Clinical severity staging:</span><br />limited (unifocal) vs. advanced (multifocal) disease
                            </p>

                            <p class="flex-1 px-4 py-3">
                                <span class="font-bold">ECIL-6 staging*:</span><br />limited (stages I–II), advanced forms (stages III–IV)
                            </p>
                        </div>
                    </div>
                </div>

                <!-- ACCORDION 3  -->
                <div>
                    <div 
                        class="border border-blue rounded-lg p-5 md:px-12 cursor-pointer text-blue flex justify-between" 
                        @click="toggleAccordion4"
                    >
                        <h1 class="text-lg font-light">
                            PTLD can be classified into six distinct subclasses which have been specified by the WHO<sup>21</sup>
                        </h1>
                        <img
                            src="@/assets/img/chevron-down-blue.svg"
                            alt="Icon"
                            class="w-6 h-6 mr-5"
                            :class="[isAccordion4Open ? 'transform rotate-180' : '']"
                        />
                    </div>
     
                    <div v-if="isAccordion4Open" class="p-5 md:px-12"> 
                        <h3 class="font-light my-8 leading-tight text-green">
                            The WHO recognizes distinct histological subtypes of PTLD<sup>21</sup>
                        </h3>

                        <p class="font-bold text-right">
                            % of EBV+<br />disease
                        </p>

                        <div class="bg-blue rounded-lg w-70% sm:w-80% md:w-90%">
                            <h4 class="py-3 text-2xl text-white font-bigShoulders">
                                Non-destructive PTLD (21%)<sup>22</sup>
                            </h4>
                        </div>

                        <div class="border border-gray-200 rounded-lg flex flex-nowrap items-center p-3 gap-4">
                            <ul class="list-disc text-left pl-3">
                                <li>
                                    Plasmacytic hyperplasia
                                </li>
                                <li>Infectious mononucleosis-like PTLD</li>
                                <li>Florid follicular hyperplasia</li>
                            </ul>

                            <span class="font-bold text-right ml-auto">
                                Most cases
                            </span>
                        </div>

                        <div class="bg-blue rounded-lg w-70% sm:w-80% md:w-90%">
                            <h4 class="py-3 text-2xl text-white font-bigShoulders">
                                Destructive PTLD (79%)<sup>22</sup>
                            </h4>
                        </div>

                        <div class="border border-gray-200 rounded-lg flex flex-nowrap items-center p-3 gap-4">
                            <ul class="list-disc text-left pl-3">
                                <li>
                                    Polymorphic PTLD
                                </li>
                                <li>cHL PTLD / cHL-like PTLD</li>
                            </ul>

                            <span class="font-bold text-right ml-auto">
                                &gt;90%
                            </span>
                        </div>

                        <div class="border border-gray-200 rounded-lg flex flex-nowrap items-center p-3 gap-4">
                            <ul class="list-disc text-left pl-3">
                                <li>
                                    Monomorphic PTLD (DLBCL, Burkitt lymphoma, plasma cell neoplasms, T-cell / NK-cell lymphomas
                                </li>
                            </ul>

                            <span class="font-bold text-right ml-auto whitespace-nowrap">
                                Variable %
                            </span>
                        </div>
                    </div>
                </div>
                <!-- ACCORDIAN SECTION END  -->
            </div>
        </div>

        <!-- section 7 -->
        <div class="bg-blue w-full text-white flex justify-center items-center py-12 md:py-16 px-6 md:px-6">
            <div class="container mx-auto flex flex-col items-center text-center">
                <h1 class="text-4xl mb-16 font-semibold">
                    EBV<span class="text-green">+</span> PTLD requires early diagnosis and a targeted treatment plan
                </h1>

                <router-link to="/management">
                    <button 
                        class="py-3 px-8 font-semibold rounded-lg bg-blue text-white cta-button flex items-center justify-center"
                    >
                        Find Out More
                        <img
                            src="@/assets/img/Arrow_Right.svg"
                            class="ml-5 animated-icon"
                        />
                    </button>
                </router-link>
            </div>
        </div>


        <!-- section 8 - references - align left -->
        <div class="bg-white w-full align-center py-12 md:py-16 px-6 md:px-6 text-blue">
            <div class="container mx-auto">
                <p class="mb-5">
                    Abbreviations: cHL, classical Hodgkin lymphoma; DLBCL, diffuse large B-cell lymphoma; EBV, Epstein-Barr virus; ECIL, European Conference on Infections in Leukemia; DNA, deoxyribonucleic acid; GvHD, graft vs host disease; HCT, hematopoietic cell transplant; HLA, human leukocyte antigen; NK, natural killer; PTLD, post-transplant lymphoproliferative disease; SOT, solid organ transplantation.
                </p>
                <h2 class="font-light text-green mb-5">
                    References
                </h2>
                <ol class="list-decimal pl-5">
                    <li>Fujimoto A, et al. Cancers (Basel) 2020;12:328.</li>
                    <li>Hart A, et al. Am J Transplant 2021;21(S2):21–137.</li>
                    <li>Kwong AJ, et al. Am J Transplant 2021;21(S2):208–315.</li>


                    <li>Colvin M, et al. Am J Transplant 2021;21(S2):356–440.</li>

                    <li>Valapour M, et al. Am J Transplant 2021;21(S2):441–520.</li>

                    <li>Kandaswamy R, et al. Am J Transplant 2023;21(S2):138–207.</li>

                    <li>Horslen SP, et al. Am J Transplant 2021;21 Suppl 2:316–355.</li>
                    <li>Dierickx D, et al. N Engl J Med 2018;378:549–562.</li>

                    <li>Dierickx D, et al. Leuk Lymphoma 2013;54(11):2433–2440.</li>

                    <li>García‐Cadenas I, et al. Eur J Haematol 2019;102(6):465–471.</li>

                    <li>Tai R, et al. Br J Radiol 2015;88(1052):20140861.</li>

                    <li>Socié G, et al. Bone Marrow Transplant 2024;59:52–58 .</li>

                    <li>Hjellbakk HK, et al. Centr Eur J Immunol 2020;45(2):233–236.</li>
                    <li>Lindsay J, et al. Curr Opin Infect Dis 2021;34:635–645.</li>
                    <li>Al-Mansour Z, et al. Curr Hemetol Malig Rep 2013;8(3):173–183.</li>
                    <li>Ru Y, et al. Eur J Haematol 2018;101:283–290.</li>

                    <li>Styczynski J, & Giebel S EBMT Handbook 2019; Chapter 45.</li>
                    <li>Abbas F, et al. World J Transplant 2020;10(2):29–46.</li>
                    <li>Allen UD, et al. Clin Transplant 2019;33(9):1–22.</li>
                    <li>Styczynski J, et al. Sixth European Conference on Infections in Leukemia (ECIL-6) guidelines. Haematologica 2016;101(7):803–811.</li>
                    <li>Atallah-Yunes S, et al. Br J Haematol 2023;201:383–395.</li>
                    <li>Lui Y et al. Cancers. 2023;15(3):976.</li>
                </ol>
            </div>
        </div>
    </div>
</template>
  
<script>
import bg1 from '@/assets/img/monitor_bg.svg';
import bg2 from '@/assets/img/hero3-final.svg';


export default {
    name: 'MonitoringPage',
    data() {
        return {
            selectedView: 'view1',
            isAccordion1Open: false,
            isAccordion2Open: false,
            isAccordion3Open: false,
            isAccordion4Open: false,
            bg1,
            bg2,
            isMobile: window.innerWidth <= 768
        };
    },
    mounted() {
        // Update `isMobile` on resize
        window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        selectView(view) {
            this.selectedView = view;
        },
        toggleAccordion1() {
            this.isAccordion1Open = !this.isAccordion1Open;
        },
        toggleAccordion2() {
            this.isAccordion2Open = !this.isAccordion2Open;
        },
        toggleAccordion3() {
            this.isAccordion3Open = !this.isAccordion3Open;
        },
        toggleAccordion4() {
            this.isAccordion4Open = !this.isAccordion4Open;
        },
        handleResize() {
            this.isMobile = window.innerWidth <= 768;
        },
        scrollToSection(sectionId) {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });

                setTimeout(() => {
                    window.scrollBy(0, -20);
                }, 600);
            }
        }
    }
};
</script>

<style lang="css" scoped>

.gradient-custom {
    background: radial-gradient(circle, #FBFBFB 41%, #CDD6DD 100%);

}

</style>

<style scoped>
/* Calculate padding based on aspect ratio; if your image is 16:9, use 56.25% */
.hero-section {
    width: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    /* padding-bottom: 56.25%;  */
    padding-bottom: 43.75%; 

    position: relative;
}

/* Inner container */
.text-content {
    position: absolute;
    top: 50%;
    left:71%;
    transform: translate(-50%, -50%);
    text-align: left;
    font-size: calc(1.2vw + 1em); /* Responsive font size */
    line-height: 1.2;
    max-width: 100%;
}

.hero-h1 {
    font-size: calc(1vw + 0.4em); /* Responsive heading size */
    font-weight: semibold;
    margin-bottom: 0.5em;
}

.hero-p {
    font-size: calc(0.5vw + 0.3em); /* Responsive paragraph size */
    font-weight: light;
}

.home-down-arrow {
    position: absolute;
    bottom: 6%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(2vw + 1em); /* Responsive width */
    height: calc(2vw + 1em); /* Responsive height */
    cursor: pointer;
}

.accordion-image {
    width: 100%;
    max-width: 100%; /* Adjust if you want some margin */
    height: auto; /* Maintain aspect ratio */
}

.hover-underline {
    position: relative;
    display: inline-block;
}

.hover-underline::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px; /* Position the underline just below the text */
    width: 100%;
    height: 2px;
    background-color: currentColor;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease;
}

.hover-underline:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.cta-button:hover .animated-icon {
    transform: translateX(5px); /* Adjust the value for more or less movement */
    transition: transform 0.3s ease; /* Smooth animation */
}


</style>