<template>
    <footer class="bg-blue text-white py-24">
        <div v-if="!isMobile" class="container mx-auto grid grid-cols-4 gap-4">
            <div class="col-span-1 text-center px-3">
                <div class="flex gap-4 my-12 items-center justify-center">
                    <a class="font-light text-lg hover:text-green" href="https://legal.pierrefabrepharmaceuticals.com/privacy/" target="_blank">
                        Privacy Policy
                    </a>
                    <span class="font-light">|</span>
                    <a class="font-light text-lg hover:text-green" href="https://legal.pierrefabrepharmaceuticals.com/terms/" target="_blank">
                        Terms of Use
                    </a>
                </div>
                <p class="text-sm text-center">
                    © 2024 Pierre Fabre Pharmaceuticals, Inc. <br />All rights reserved.<br />November 2024 | PF-0086
                </p>
            </div>
            <div class="col-span-1 text-center px-3">

            </div>
            <div class="col-span-1 text-center px-3">

            </div>
            <div class="col-span-1 flex flex-col items-center text-center px-3">
                <img src="@/assets/img/pierre_logo.svg" class="h-32 mb-5" />
            </div>
        </div>




        <div v-else-if="isMobile" class="container mx-auto px-12">
            <div class="flex flex-col px-4 md:px-0 md:flex-row justify-between">
                <div class="flex flex-col">
                    <div class="flex flex-wrap gap-y-4 gap-x-24 items-center justify-center">
                    </div>

                    <div class="flex gap-4 my-12 items-center justify-center">
                        <a class="font-light text-lg hover:text-green" href="https://legal.pierrefabrepharmaceuticals.com/privacy/" target="_blank">
                            Privacy Policy
                        </a>
                        <span class="font-light">|</span>
                        <a class="font-light text-lg hover:text-green" href="https://legal.pierrefabrepharmaceuticals.com/terms/" target="_blank">
                            Terms of Use
                        </a>
                    </div>

                    <p class="text-sm text-center">
                        © 2024 Pierre Fabre Pharmaceuticals, Inc. <br />All rights reserved.<br />November 2024 | PF-0086
                    </p>
                </div>

                <div class="flex flex-col items-center justify-center py-8">
                    <img src="@/assets/img/pierre_logo.svg" class="h-32 mb-5" />
                </div>
            </div>
        </div>
    </footer>
</template>
  
<script>
export default {
    name: 'PageFooter',
    data() {
        return {
            isMobile: window.innerWidth <= 768,
        }
    },
    mounted() {
        // Update `isMobile` on resize
        window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.isMobile = window.innerWidth <= 768;
        },
    }
};
</script>
