<template>
    <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">

        <!--
            Add icons here
            Give each icon a unique id in the format `#icon-[name]`
        -->

        <symbol id="icon-close" viewBox="0 0 28 28">
            <path d="M28 2.82L25.18 0 14 11.18 2.82 0 0 2.82 11.18 14 0 25.18 2.82 28 14 16.82 25.18 28 28 25.18 16.82 14 28 2.82z" />
        </symbol>
    </svg>
</template>

<script>
export default {
    name: 'IconSet'
}
</script>
