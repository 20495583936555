<template>
    <div class="w-full sticky top-0 left-0 bg-white shadow-md overlay-menu">
        <nav class="container mx-auto py-6 px-4 md:px-0 flex justify-between items-center">
            <!-- Logo -->
            <div class="logo">
                <router-link to="/home">
                    <img src="@/assets/logo.png" alt="Logo" class="max-h-7 md:max-h-10" />
                </router-link>
            </div>

            <!-- Desktop Navigation Links (hidden on mobile) -->
            <div class="hidden lg:flex items-center gap-3">
                <router-link to="/home" class="text-gray-700 text-xl px-3 hover:text-blue-600" active-class="text-green">
                    Overview
                </router-link>
                <span class="text-xl font-light">|</span>
                <router-link to="/pathogenesis" class="text-gray-700 text-xl px-3 hover:text-blue-600" active-class="text-green">
                    Pathogenesis
                </router-link>
                <span class="text-xl font-light">|</span>
                <router-link to="/monitoring" class="text-gray-700 text-xl px-3 hover:text-blue-600" active-class="text-green">
                    Monitoring
                </router-link>
                <span class="text-xl font-light">|</span>
                <router-link to="/management" class="text-gray-700 text-xl px-3 hover:text-blue-600" active-class="text-green">
                    Management
                </router-link>
                <router-link to="/resources" class="bg-blue flex items-center text-white font-bold rounded-xl uppercase py-2 px-4 hover:bg-blue-600">
                    <span>Sign Up</span><img src="@/assets/img/sms.svg" alt="SMS Icon" class="size-6 ml-2 mb-1 inline-block" />
                </router-link>
            </div>

            <!-- Hamburger Icon for Mobile (visible on mobile only) -->
            <div class="lg:hidden flex items-center">
                <button class="text-gray-700 focus:outline-none" @click="toggleMobileMenu">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-8 w-8"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M4 6h16M4 12h16M4 18h16"
                        />
                    </svg>
                </button>
            </div>
        </nav>

        <!-- Mobile Navigation Menu (visible when hamburger is clicked) -->
        <div v-if="isMobileMenuOpen" class="lg:hidden bg-white shadow-lg overlay-menu">
            <div class="flex flex-col text-center p-4 space-y-4">
                <router-link
                    to="/home"
                    class="text-gray-700 text-lg px-4 py-2 hover:text-blue-600"
                    active-class="text-green"
                    @click="toggleMobileMenu"
                >
                    Overview
                </router-link>
                <router-link
                    to="/pathogenesis"
                    class="text-gray-700 text-lg px-4 py-2 hover:text-blue-600"
                    active-class="text-green"
                    @click="toggleMobileMenu"
                >
                    Pathogenesis
                </router-link>
                <router-link
                    to="/monitoring"
                    class="text-gray-700 text-lg px-4 py-2 hover:text-blue-600"
                    active-class="text-green"
                    @click="toggleMobileMenu"
                >
                    Monitoring
                </router-link>
                <router-link
                    to="/management"
                    class="text-gray-700 text-lg px-4 py-2 hover:text-blue-600"
                    active-class="text-green"
                    @click="toggleMobileMenu"
                >
                    Management
                </router-link>
                <router-link to="/resources" class="text-white bg-blue font-bold rounded-xl uppercase py-2 px-4 hover:bg-blue-600" @click="toggleMobileMenu">
                    Sign Up
                </router-link>
            </div>
        </div>

        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'PageHeader',
    data() {
        return {
            isMobileMenuOpen: false
        };
    },
    methods: {
        toggleMobileMenu() {
            this.isMobileMenuOpen = !this.isMobileMenuOpen;
        }
    }
};
</script>

<style lang="css" scoped>

.overlay-menu {
    z-index: 99 !important;
}

</style>
