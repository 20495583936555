<template>
    <button
        class="modal__open-button"
        :class="buttonClass"
        @click.prevent="openModal"
    >
        <slot name="button"></slot>
    </button>

    <teleport to="body">
        <div
            v-show="modalOpen"
            role="dialog"
            class="modal"
            :class="customClass"
        >
            <div class="modal__mask" @click="closeModal"></div>

            <div class="modal__body">
                <button
                    class="modal__close-button"
                    aria-label="Close modal"
                    @click="closeModal"
                >
                    <UseIcon name="close" />
                </button>

                <div class="modal__content">
                    <slot></slot>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script>
export default {
    props: {
        customClass: {
            type: String,
            default: ''
        },
        buttonClass: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            modalOpen: false
        };
    },
    methods: {
        closeModal () {
            this.modalOpen = false;
            document.body.classList.remove('no-scroll');
        },
        openModal () {
            this.modalOpen = true;
            document.body.classList.add('no-scroll');
        },
    },
}
</script>
