import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index';
import UseIcon from '@/components/UseIcon';
import PopupModal from '@/components/PopupModal';
import { createMetaManager } from 'vue-meta';
import './index.css'

const app = createApp(App);
app.use(router);
app.use(createMetaManager());
app.component('UseIcon', UseIcon);
app.component('PopupModal', PopupModal);
app.mount('#app');
