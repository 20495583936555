<template>
    <div>
        <div
            v-if="isMobile"
            :class="['flex flex-col justify-center', isMobile ? 'gradient-bg' : 'bg-cover bg-no-repeat bg-left-bottom']"
            :style="!isMobile ? { backgroundImage: `url(${bg1})` } : {}"
        >
            <div class="container mx-auto flex flex-col md:flex-row items-center ">
                <div class="p-6 md:p-8 flex flex-col text-darkblue mt-16 text-center md:text-left">
                    <h1 class="text-3xl md:text-4xl font-semibold mb-4">
                        Managing patients with Epstein-Barr virus positive post-transplant lymphoproliferative disease (EBV<span class="text-green">+</span> PTLD)
                    </h1>
                    <p class="text-base font-light md:text-xl">
                        EBV+ PTLD is a rare, life-threatening and often fatal malignancy with no FDA-approved treatments<sup>1,2</sup>
                    </p>
                </div>
                <img
                    src="@/assets/img/side_jenga_2.png"
                    alt="Scroll Arrow"
                    class="w-full mt-12"
                />
            </div>
            <div class="mb-12">
                <div class="flex w-full justify-center items-center text-center" @click="scrollToSection('section2')">
                    <img
                        src="@/assets/img/down-arrow.svg"
                        alt="Scroll Arrow"
                        class="ml-2 w-12 pl-0"
                    />
                </div>
            </div>
        </div>

        <div
            v-if="!isMobile"
            class="hero-section flex items-center justify-center text-darkblue"
            :style="{ backgroundImage: `url(${bg1})` }"
        >
            <div class="text-content">
                <h1 class="hero-h1">
                    Managing patients with Epstein-Barr virus positive post-transplant lymphoproliferative disease (EBV<span class="text-green">+</span> PTLD)
                </h1>
                <p class="hero-p">
                    EBV+ PTLD is a rare, life-threatening and often fatal malignancy with no FDA-approved treatments<sup>1,2</sup>
                </p>
            </div>
            <img
                src="@/assets/img/down-arrow.svg"
                alt="Scroll Arrow"
                class="home-down-arrow"
                @click="scrollToSection('section2')"
            />
        </div>

        <div id="section2" class="bg-blue w-full py-12 md:py-16 px-6 md:px-6">
            <div class="flex flex-col lg:flex-row container mx-auto items-center">
                <!-- Left Column (50%) -->
                <div class="text-white w-full lg:w-1/3 lg:pr-24 p-6 lg:p-8">
                    <h3 class="font-light text-white mb-10 text-center lg:text-left">
                        Current standard of care options for EBV<span class="text-green">+</span> PTLD include reduction of immunosuppression (RIS) and anti-CD20 ab ± chemotherapy but reported response is variable<sup>3–7</sup>
                    </h3>
                </div>

                <!-- Right Column (50%) -->

                <!-- todo7: the below three boxes need to link and smooth scroll to the respective sections on the page with ids: 'reduction', 'chemo', and 'adoptive' -->
                <div class="w-full lg:w-2/3 flex flex-col lg:flex-row justify-between">
                    <!-- Top Row with three columns -->
                    <!-- Top Row with three columns -->
                    <div class="flex flex-col lg:flex-row w-full gap-4">
                        <div class="cursor-pointer w-full lg:w-1/3 p-2 rounded-3xl border py-12 px-4" @click="scrollToSection('reduction')">
                            <h5 class="text-white mb-6 lg:mb-20 min-h-12">
                                Reduction of <br />immunosuppression<sup>8</sup>
                            </h5>
                            <h5 class="text-white flex justify-start">
                                <span class="hover-underline">Read more</span>
                                <img
                                    src="@/assets/img/Arrow_Right.svg"
                                    alt="Graph 1"
                                    class="ml-2 animated-icon"
                                />
                            </h5>
                        </div>
                        <div class="cursor-pointer w-full lg:w-1/3 p-2 rounded-3xl border py-12 px-4" @click="scrollToSection('chemo')">
                            <h5 class="text-white mb-6 lg:mb-20 min-h-12">
                                Anti-CD20 ab ± <br />chemotherapy<sup>8</sup>
                            </h5>
                            <h5 class="text-white flex justify-start">
                                <span class="hover-underline">Read more</span>
                                <img
                                    src="@/assets/img/Arrow_Right.svg"
                                    alt="Graph 1"
                                    class="ml-2"
                                />
                            </h5>
                        </div>
                        <div class="cursor-pointer w-full lg:w-1/3 p-2 rounded-3xl border py-12 px-4" @click="scrollToSection('adoptive')">
                            <h5 class="text-white mb-6 lg:mb-20 min-h-12">
                                Adoptive T-<br />cell immunotherapies<sup>8–10</sup>
                            </h5>
                            <h5 class="text-white flex justify-start">
                                <span class="hover-underline">Read more</span>
                                <img
                                    src="@/assets/img/Arrow_Right.svg"
                                    alt="Graph 1"
                                    class="ml-2"
                                />
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- section 3 -->
        <div class="bg-white w-full py-12 md:py-16 px-6 md:px-6">
            <div class="container mx-auto">
                <h2 class="text-3xl font-bold text-darkblue mb-8 text-center">
                    There are no indicated treatments for EBV<span class="text-green">+</span> PTLD, including current management strategies
                </h2>

                <div class="flex justify-center mb-8 text-green">
                    <h3 class="font-light text-center">
                        NCCN recommendations for the treatment of EBV+ PTLD*<sup>8</sup>
                    </h3>
                </div>

                <!-- Buttons -->
                <div class="flex justify-center mb-16 gap-4">
                    <button 
                        class="flex-1 py-3 font-semibold rounded-lg"
                        :class="{
                            'bg-blue text-white': selectedView === 'view1',
                            'bg-white text-blue inactive-border rounded-md': selectedView !== 'view1'
                        }"
                        @click="selectView('view1')"
                    >
                        First-line options**
                    </button>
                    <button 
                        class="flex-1 py-3 font-semibold rounded-lg"
                        :class="{
                            'bg-blue text-white': selectedView === 'view2',
                            'bg-white text-blue inactive-border rounded-md': selectedView !== 'view2'
                        }"
                        @click="selectView('view2')"
                    >
                        Second-line options
                    </button>
                </div>

                

                <!-- Display SVG based on selected button -->
                <div class="mt-8">
                    <div v-if="selectedView === 'view1'">
                        <img
                            src="@/assets/img/table1.svg"
                            alt="Graph 1"
                            class="mx-auto"
                        />
                        <br />
                        <p>*Treatment options for EBV+ PTLD depend on the histologic subtype and should be individualized.<sup>8</sup></p>
                        <p>**Surgery is also an option for localized PTLD.<sup>8</sup></p><br />

                        <p class="text-xs">
                            These recommendations are provided only for educational and informational purposes. They do not constitute medical advice and are not intended to replace licensed
                            practitioners' professional medical judgment for the best care of their patients. The medical products mentioned here may or may not have FDA approval for their stated use.
                            Pierre Fabre does not intend these messages to endorse the unapproved use of a medical product.
                        </p><br />
                    </div>
                    <div v-else-if="selectedView === 'view2'">
                        <img
                            src="@/assets/img/table2.svg"
                            alt="Graph 2"
                            class="mx-auto"
                        />
                        <br />
                        <p>*Treatment options for EBV+ PTLD depend on the histologic subtype and should be individualized.<sup>8</sup></p>
                        <p>**Surgery is also an option for localized PTLD.<sup>8</sup></p><br />

                        <p class="text-xs">
                            These recommendations are provided only for educational and informational purposes. They do not constitute medical advice and are not intended to replace licensed
                            practitioners' professional medical judgment for the best care of their patients. The medical products mentioned here may or may not have FDA approval for their stated use.
                            Pierre Fabre does not intend these messages to endorse the unapproved use of a medical product.
                        </p><br />
                    </div>

                    <p class="text-xs">
                        Adapted with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Guideline Name V.3.2024. © 2024 National Comprehensive Cancer Network, Inc. All rights reserved. The NCCN Guidelines® and illustrations herein may not be reproduced in any form for any purpose without the express written permission of NCCN. To view the most recent and complete version of the NCCN Guidelines, go online to NCCN.org. NCCN makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.
                    </p>
                </div>
                <div class="mt-8 flex text-xs">
                    <img
                        src="@/assets/img/nccn.jpg"
                        class="h-8 text-left pr-2"
                    />
                    <span class="pt-2">Updated: 2024</span>
                </div>
            </div>
        </div>


        <!-- section 2  -->
        <div id="reduction" class="bg-blue w-full py-12 md:py-16 pb-0 px-6 md:px-6">
            <h1
                class="text-center text-3xl md:text-4xl text-white font-semibold mb-12"
            >
                Reduction of Immunosuppression
            </h1>
            <div class="flex flex-col md:flex-row container mx-auto items-center">
                <!-- Left Column (50%) -->
                <div class="text-white w-full md:w-1/2 lg:px-24">
                    <h3 class="font-light text-green mb-10 text-center">
                        There are no uniform guidelines on reduction of immunosuppression<sup>8,11</sup> and there may be risks associated<sup>9,1</sup>
                    </h3>
                </div>
  
                <!-- Right Column (50%) -->
                <div class="w-full md:w-1/2 flex flex-col justify-between">
                    <!-- Top Row with two equal columns -->
                    <img
                        src="@/assets/img/orbs.png"
                        class="h-96 text-left object-contain"
                    />
                </div>
            </div>
        </div>

        <!-- section 2  -->
        <div id="chemo" class="gradient-bg w-full py-12 md:py-16 px-6 md:px-6">
            <h1
                class="md:px-16 text-center text-4xl md:text-5xl text-darkblue font-semibold  mb-12"
            >
                Anti-CD20 ± chemotherapy
            </h1>
            <div class="flex flex-col md:flex-row container mx-auto items-center">
                <!-- Left Column (50%) -->
                <div class="text-white w-full md:w-1/2 lg:px-12">
                    <img
                        src="@/assets/img/t-cell-stack.png"
                        class="mb-4 object-contain"
                    />
                    <p class="text-black font-light text-xs">
                        For illustrative purposes only
                    </p>
                </div>
  
                <!-- Right Column (50%) -->
                <div class="w-full md:w-1/2 flex flex-col justify-between md:pl-16 mt-5">
                    <!-- Top Row with two equal columns -->
                    

                    <h3 class="font-light text-green mb-10 text-center md:text-left">
                        Response to anti-CD20 ab +/- sequential treatment with chemotherapy or anti-CD20 ab + chemotherapy varies in EBV+ PTLD patients<sup>3–7</sup>
                    </h3>
                    <ul class="pl-4 list-disc mb-6 text-darkblue">
                        <li class="mb-2">
                            Response rate of ~55% with anti-CD20 ab monotherapy<sup>3</sup>
                        </li>
                        <li class="mb-2">
                            50–70% of patients achieve a complete response to treatment with anti-CD20 ab as monotherapy or in combination with chemotherapy<sup>4–7</sup>
                        </li>
                    </ul>
                    <h3 class="font-light text-green mb-10 text-center md:text-left">
                        PTLD patients can experience chemotherapy-related toxicities<sup>4–6</sup>
                    </h3>
                    <ul class="pl-4 list-disc mb-6 text-darkblue">
                        <li class="mb-2">
                            ~60% and ~35–40% of patients experienced Grade 3–4 leukopenia and infection, respectively<sup>4,5</sup>
                        </li>
                        <li class="mb-2">
                            Treatment-related mortality with chemotherapy following failure of anti-CD20 ab has ranged from 7–13% in retrospective and prospective clinical trials<sup>4–6</sup>
                        </li>
                    </ul>
                </div>
            </div>

            <h1
                class="md:px-16 text-center text-4xl font-semibold mt-5 text-green"
            >
                Chemotherapy is not an option for all patients with EBV+ PTLD
            </h1>
        </div>

        <div id="adoptive" class="bg-white w-full py-12 md:py-16 px-4 md:px-6">
            <div class="container mx-auto text-center">
                <h1
                    class="md:px-16 text-2xl md:text-4xl font-semibold mb-12 leading-snug text-darkblue"
                >
                    Adoptive T-cell immunotherapies under investigation are designed to restore T-cell immunity in patients with EBV+ PTLD<sup>9,10</sup>
                </h1>
                <h3 class=" text-xl md:text-2xl font-light text-green mb-10">
                    Different sources and applications for adoptive immunotherapy in EBV+ PTLD<sup>9,10</sup>
                </h3>

                <!-- table goes here  -->

                <div class="mx-auto w-full overflow-hidden rounded-xl">
                    <table class="table-fixed w-full border-collapse mb-5 text-blue last-row-left">
                        <thead>
                            <tr>
                                <th class="w-1/3 font-bigShoulders py-4 px-2 bg-blue text-white text-center text-lg font-normal rounded-tl-xl">
                                    Transplantation cell type
                                </th>
                                <th class="w-1/3 font-bigShoulders py-4 px-2 bg-blue text-white text-center text-lg font-normal">
                                    Allogeneic HCT
                                </th>
                                <th class="w-1/3 font-bigShoulders py-4 px-2 bg-blue text-white text-center text-lg font-normal rounded-tr-xl">
                                    SOT
                                </th>
                            </tr>
                        </thead>
                        <tbody class="">
                            <!-- Row 1 -->
                            <tr class="border-t border-b border-gray-300">
                                <td class="w-1/3 bg-backgroundblue border-l border-r border-gray-300 py-4 px-2 text-left">
                                    Donor lymphocytes<sup>9</sup>
                                </td>
                                <td class="w-1/3 border-l border-r border-gray-300 py-4 px-2 text-center">
                                    ORR: 72% - but with risk of GvHD
                                </td>
                                <td class="w-1/3 border-l border-r border-gray-300 py-4 px-2 text-center">
                                    N/A
                                </td>
                            </tr>
                            <!-- Row 2 -->
                            <tr class="border-t border-b border-gray-300">
                                <td class="w-1/3 bg-backgroundblue border-l border-r border-gray-300 py-4 px-2 text-left">
                                    Autologous EBV-CTLs<sup>9</sup>
                                </td>
                                <td class="w-1/3 border-l border-r border-gray-300 py-4 px-2 text-center">
                                    N/A
                                </td>
                                <td class="w-1/3 border-l border-r border-gray-300 py-4 px-2 text-center break-words">
                                    Use is limited due to ongoing immuno&shy;suppression
                                </td>
                            </tr>
                            <!-- Row 3 -->
                            <tr class="border-t border-b border-gray-300">
                                <td class="w-1/3 bg-backgroundblue border-l border-r border-gray-300 py-4 px-2 text-left">
                                    Donor-derived EBV-CTLs<sup>9</sup>
                                </td>
                                <td class="w-1/3 border-l border-r border-gray-300 py-4 px-2 text-center">
                                    ORR: 68% - without significant toxicity
                                </td>
                                <td class="w-1/3 border-l border-r border-gray-300 py-4 px-2 text-center">
                                    N/A
                                </td>
                            </tr>
                            <!-- Row 4 -->
                            <tr class="border-t border-b border-gray-300">
                                <td class="w-1/3 bg-backgroundblue border-l border-r border-gray-300 py-4 px-2 text-left">
                                    Third party EBV-CTLs<sup>10</sup>
                                </td>
                                <td class="w-1/3 border-l border-r border-gray-300 py-4 px-2 text-center">
                                    ORR: 68% - without significant toxicity
                                </td>
                                <td class="w-1/3 border-l border-r border-gray-300 py-4 px-2 text-center">
                                    ORR: 54% - without significant toxicity
                                </td>
                            </tr>
                            <!-- Row 5 - Joined Cell with Centered Content -->
                            <tr class="">
                                <td class="w-1/3 bg-backgroundblue  py-4 px-2 text-left ">
                                    Chimeric antigen receptor T-cells (non-specific to EBV)<sup>9</sup>
                                </td>
                                <td colspan="2" class=" py-4 px-2 text-center">
                                    Promising results - however rejection and GvHD triggered <br />by cytokine release syndrome is a potential threat
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- table ends here  -->

                <p class="text-left font-light">
                    Adapted from Dierickx D, et al. Curr Opin Oncol 2022;34:413–421.
                </p>

                <!-- removed per feedback -->
                <!-- <div class="flex w-full bg-green text-white rounded-lg px-5 mt-12">
                    <div class="w-1/8 pt-2">
                        <img
                            src="@/assets/img/quote-icon.svg"
                            alt="Icon"
                            class="w-16 h-16"
                        />
                    </div>
                    <div class="w-7/8 items-center justify-center text-center py-5">
                        <h1 class="font-normal leading-tight font-bigShoulders">
                            It’s really essential that these patients in both [SOT and HCT] populations have alternative therapeutic options
                        </h1>
                        <p>Dr Susan Prockop, sourced from youtube.com/watch?v=nB_QtELAbYw</p>
                    </div>
                </div> -->
            </div>
        </div>



        <!-- section 7 -->
        <div class="bg-blue w-full text-white flex justify-center items-center py-12 md:py-16 px-6 md:px-6">
            <div class="container mx-auto flex flex-col items-center text-center">
                <h1 class="text-3xl mb-16 px-3 font-semibold">
                    With no FDA-approved treatment options for relapsed or refractory EBV<span class="text-green">+</span> PTLD there is a significant unmet need for new therapies<sup>1,2</sup>
                </h1>

                <router-link to="/resources">
                    <button 
                        class="py-3 px-8 font-semibold rounded-lg bg-blue text-white cta-button flex items-center justify-center"
                    >
                        Find Out More
                        <img
                            src="@/assets/img/Arrow_Right.svg"
                            class="ml-5 animated-icon"
                        />
                    </button>
                </router-link>
            </div>
        </div>


        <!-- section 8 - references - align left -->
        <div class="bg-white w-full align-center py-12 md:py-16 px-6 md:px-6 text-blue">
            <div class="container mx-auto">
                <p class="mb-5">
                    Abbreviations: Ab, antibody; CTL, cytotoxic T-lymphocyte; EBV, Epstein-Barr virus; FDA, Food and Drug Administration; GvHD, graft vs host disease; HCT, hematopoietic cell transplant; N/A, not applicable; NCCN; National Comprehensive Cancer Network; ORR, overall response rate; PTLD, post-transplant lymphoproliferative disease; RIS, reduction of immunosuppression; SOT, solid organ transplantation.
                </p>
                <h2 class="font-light text-green mb-5">
                    References
                </h2>
                <ol class="list-decimal pl-5">
                    <li>Socié G, et al. Bone Marrow Transplant 2024;59:52–58.</li>
                    <li>Dharnidharka V, et al. HemaSphere 2022;6(Abstract):997–998.</li>

                    <li>Allen UD, et al. Clin Transplant 2019;33(9):e13652.</li>
                    <li>Trappe RU, et al. J Clin Oncol 2017;35(5):536–543.</li>
                    <li>Trappe R, et al. Lancet Oncol 2012;13:196–206.</li>

                    <li>Burns DM, et al. Transplantation 2020;104;2582–2590.</li>
                    <li>Jagadeesh D, et al. J Clin Onco l2020;38(15):e20026.</li>

                    <li>NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®). B-Cell Lymphomas 2024. Available at: https://www.nccn.org/guidelines/guidelines-detail?category=1&id=1480. Accessed November 2024.</li>
                    <li>Dierickx D, et al. Curr Opin Oncol 2022;34:413–421.</li>
                    <li>Prockop S, et al. J Clin Invest 2020;130(2):733–747.</li>
                    <li>Zimmermann H, et al. Transplantation 2018;102:1914–1923.</li>
                    <li>Reshef R, et al. Am J Transplant 2011;11:336–347.</li>
                </ol>
            </div>
        </div>
    </div>
</template>
  
<script>
import bg1 from '@/assets/img/mgmt_bg.svg';
import bg2 from '@/assets/img/hero4-final.svg';


export default {
    name: 'ManagementPage',
    data() {
        return {
            selectedView: 'view1',
            isAccordionOpen: false,
            accordionItems: [
                { title: 'Accordion Item 1', content: 'Content for accordion item 1.' },
            ],
            bg1,
            bg2,
            isMobile: window.innerWidth <= 768,
        };
    },
    mounted() {
        // Update `isMobile` on resize
        window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        selectView(view) {
            this.selectedView = view;
        },
        toggleAccordion() {
            this.isAccordionOpen = !this.isAccordionOpen;
        },
        handleResize() {
            this.isMobile = window.innerWidth <= 768;
        },
        scrollToSection(sectionId) {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });

                setTimeout(() => {
                    window.scrollBy(0, -20);
                }, 600);
            }
        }
    }
};
</script>

<style scoped>
/* Calculate padding based on aspect ratio; if your image is 16:9, use 56.25% */
.hero-section {
    width: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    /* padding-bottom: 56.25%;  */
    padding-bottom: 43.75%; 

    position: relative;
}

/* Inner container */
.text-content {
    position: absolute;
    top: 50%;
    left: 71%;
    transform: translate(-50%, -50%);
    text-align: left;
    font-size: calc(1.2vw + 1em); /* Responsive font size */
    line-height: 1.2;
    max-width: 100%;
}

.hero-h1 {
    font-size: calc(1vw + 0.4em); /* Responsive heading size */
    font-weight: semibold;
    margin-bottom: 0.5em;
}

.hero-p {
    font-size: calc(0.5vw + 0.3em); /* Responsive paragraph size */
    font-weight: light;
}

.home-down-arrow {
    position: absolute;
    bottom: 6%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(2vw + 1em); /* Responsive width */
    height: calc(2vw + 1em); /* Responsive height */
    cursor: pointer;
}

.last-row-left {
    position: relative;
    padding: 10px; /* Adjust as needed */
    background: white;
    border-radius: 12px; /* Apply radius to the main container */
    overflow: hidden; /* Ensure contents respect the border-radius */
}

.last-row-left::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-left: 1px solid #d1d5db;
    border-right: 1px solid #d1d5db;
    border-bottom: 1px solid #d1d5db;
    border-radius: 12px; /* Match the border-radius of the main element */
    pointer-events: none;
}

.hover-underline {
    position: relative;
    display: inline-block;
}

.hover-underline::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px; /* Position the underline just below the text */
    width: 100%;
    height: 2px;
    background-color: currentColor;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease;
}

.hover-underline:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.cta-button:hover .animated-icon {
    transform: translateX(5px); /* Adjust the value for more or less movement */
    transition: transform 0.3s ease; /* Smooth animation */
}



</style>