<template>
    <div>
        <!-- <div
            v-if="isMobile"
            :class="['flex flex-col justify-center', isMobile ? 'gradient-bg' : 'bg-cover bg-no-repeat bg-left-bottom']"
            :style="!isMobile ? { backgroundImage: `url(${bg1})` } : {}"
        >
            <div class="container mx-auto flex flex-col md:flex-row items-center">
                <div class="p-6 md:p-8 flex flex-col text-darkblue mt-16">
                    <h1 class="text-3xl md:text-4xl font-semibold mb-4">
                        Sign up
                    </h1>
                    <p class="text-base font-light md:text-xl">
                        Sign up to find out more information on EBV+ PTLD
                    </p>
                </div>
                <img
                    src="@/assets/img/main_blocks.png"
                    alt="Scroll Arrow"
                    class="w-56 mt-6 mb-24"
                />
            </div>
            <div class="mb-12">
                <div class="flex w-full justify-center items-center text-center" @click="scrollToSection('section2')">
                    <img
                        src="@/assets/img/down-arrow.svg"
                        alt="Scroll Arrow"
                        class="ml-2 w-12 pl-0"
                    />
                </div>
            </div>
        </div>

        <div
            v-if="!isMobile"
            class="hero-section flex items-center justify-center text-darkblue"
            :style="{ backgroundImage: `url(${bg1})` }"
        >
            <div class="text-content">
                <h1 class="hero-h1">
                    Sign up
                </h1>
                <p class="hero-p">
                    Sign up to find out more information on EBV+ PTLD
                </p>
            </div>
            <img
                src="@/assets/img/down-arrow.svg"
                alt="Scroll Arrow"
                class="home-down-arrow"
                @click="scrollToSection('section2')"
            />
        </div> -->

        <!-- form section  -->

        <div id="section2" class="bg-white w-full py-16 px-12 lg:px-60">
            <div class="container mx-auto">
                <h2 class="text-green mb-5">
                    Connect with us
                </h2>
                <form class="space-y-8" @submit.prevent="handleSubmit">
                    <!-- Columns for form fields -->
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
                        <div class="relative">
                            <label for="firstName" class="block text-sm font-medium text-gray-700">First Name</label>
                            <input
                                id="firstName"
                                v-model="formData.firstName"
                                tabindex="1"
                                type="text"
                                placeholder="First Name"
                                class="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                                @input="clearError('firstName')"
                            />
                            <span v-if="errors.firstName" class="text-red-400 text-xs absolute left-0 top-full">{{ errors.firstName }}</span>
                        </div>
                        <div class="relative">
                            <label for="lastName" class="block text-sm font-medium text-gray-700">Last Name</label>
                            <input
                                id="lastName"
                                v-model="formData.lastName"
                                tabindex="2"
                                type="text"
                                placeholder="Last Name"
                                class="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                                @input="clearError('lastName')"
                            />
                            <span v-if="errors.lastName" class="text-red-400 text-xs absolute left-0 top-full">{{ errors.lastName }}</span>
                        </div>
                        <div class="relative">
                            <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
                            <input
                                id="email"
                                v-model="formData.email"
                                tabindex="3"
                                type="email"
                                placeholder="Email"
                                class="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                                @input="clearError('email')"
                            />
                            <span v-if="errors.email" class="text-red-400 text-xs absolute left-0 top-full">{{ errors.email }}</span>
                        </div>
                        <div class="relative">
                            <label for="phoneNumber" class="block text-sm font-medium text-gray-700">Phone Number</label>
                            <input
                                id="phoneNumber"
                                v-model="formData.phoneNumber"
                                tabindex="4"
                                type="text"
                                placeholder="Phone Number"
                                class="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                                @input="clearError('phoneNumber')"
                            />
                            <span v-if="errors.phoneNumber" class="text-red-400 text-xs absolute left-0 top-full">{{ errors.phoneNumber }}</span>
                        </div>
                        <div>
                            <label for="zipCode" class="block text-sm font-medium text-gray-700">ZIP Code</label>
                            <input
                                id="zipCode"
                                v-model="formData.zipCode"
                                tabindex="5"
                                type="text"
                                placeholder="ZIP Code"
                                class="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                            />
                        </div>
                        <div>
                            <label for="practiceName" class="block text-sm font-medium text-gray-700">Practice or Center Name</label>
                            <input
                                id="practiceName"
                                v-model="formData.practiceName"
                                tabindex="6"
                                type="text"
                                placeholder="Practice or Center Name"
                                class="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                            />
                        </div>
                        <div>
                            <label for="designation" class="block text-sm font-medium text-gray-700">Professional Designation</label>
                            <select
                                id="designation"
                                v-model="formData.designation"
                                tabindex="7"
                                class="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                            >
                                <option value="" disabled selected>
                                    Select Professional Designation
                                </option>
                                <option>Doctor</option>
                                <option>Nurse</option>
                                <option>Pharmacist</option>
                                <option>Other</option>
                            </select>

                            <div v-if="formData.designation === 'Other'" class="relative">
                                <input
                                    v-model="otherDesignation"
                                    tabindex="7"
                                    type="text"
                                    placeholder="Please specify"
                                    class="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                                />
                                <span v-if="errors.otherDesignation" class="text-red-400 text-xs absolute left-0 top-full">{{ errors.otherDesignation }}</span>
                            </div>
                        </div>
                        <div>
                            <label for="contactMethod" class="block text-sm font-medium text-gray-700">Preferred Methods of Contact</label>
                            <select
                                id="contactMethod"
                                v-model="formData.contactMethod"
                                tabindex="8"
                                class="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                            >
                                <option value="" disabled selected>
                                    Select Preferred Method of Contact
                                </option>
                                <option>Email</option>
                                <option>Phone</option>
                            </select>
                        </div>
                    </div>

                    <!-- Full-width checkboxes with SVGs -->
                    <div class="space-y-4">
                        <!-- Checkbox 1 -->
                        <div class="flex items-start relative">
                            <div tabindex="10" class="cursor-pointer" @click="toggleCheckbox('privacyPolicyAgreement')">
                                <img
                                    v-if="formData.privacyPolicyAgreement"
                                    src="@/assets/img/checked_box.svg"
                                    alt="Checked"
                                    class="h-8 w-8"
                                />
                                <img
                                    v-else
                                    src="@/assets/img/unchecked_box.svg"
                                    alt="Unchecked"
                                    class="h-8 w-8"
                                />
                            </div>
                            <label class="ml-4 text-sm text-gray-700 cursor-pointer" @click="toggleCheckbox('privacyPolicyAgreement')">
                                By submitting this form, I certify that I am a healthcare professional, and I am a resident of the United States. <br />I agree to the <a class="text-green" href="https://legal.pierrefabrepharmaceuticals.com/privacy/" target="_blank">Privacy Policy</a> and consent to the processing of my personal data per the terms outlined in the Data Privacy Notification and Consent.
                            </label>
                            <span v-if="errors.privacyPolicyAgreement" class="text-red-400 text-xs absolute left-0 top-full">{{ errors.privacyPolicyAgreement }}</span>
                        </div>

                        <!-- <h3 class="text-green font-light">
                            Let us know how you would like to hear from us
                        </h3>


                        <div class="flex items-start">
                            <div class="cursor-pointer" @click="toggleCheckbox('checkbox2')">
                                <img
                                    v-if="formData.checkbox2"
                                    src="@/assets/img/checked_box.svg"
                                    alt="Checked"
                                    class="h-8 w-8"
                                />
                                <img
                                    v-else
                                    src="@/assets/img/unchecked_box.svg"
                                    alt="Unchecked"
                                    class="h-8 w-8"
                                />
                            </div>
                            <label class="ml-4 text-sm text-gray-700 cursor-pointer" @click="toggleCheckbox('checkbox2')">
                                I would like to receive email updates and marketing communications about EBV+ PTLD from Pierre Fabre Pharmaceuticals.
                            </label>
                        </div>


                        <div class="flex items-start">
                            <div class="cursor-pointer" @click="toggleCheckbox('checkbox3')">
                                <img
                                    v-if="formData.checkbox3"
                                    src="@/assets/img/checked_box.svg"
                                    alt="Checked"
                                    class="h-8 w-8"
                                />
                                <img
                                    v-else
                                    src="@/assets/img/unchecked_box.svg"
                                    alt="Unchecked"
                                    class="h-8 w-8"
                                />
                            </div>
                            <label class="ml-4 text-sm text-gray-700 cursor-pointer" @click="toggleCheckbox('checkbox3')">
                                I would like to have a conversation with a Pierre Fabre Pharmaceuticals representative.
                            </label>
                        </div> -->
                    </div>

                    <!-- Submit button -->
                    <button
                        :disabled="loading"
                        tabindex="12"
                        type="submit"
                        class="w-full bg-blue text-white py-3 rounded-md flex justify-center items-center space-x-2 font-semibold"
                    >
                        <span>Submit</span>
                        <img src="@/assets/img/submit_arrow.svg" alt="Submit Icon" class="w-4 h-4" />
                    </button>
                </form>
            </div>
        </div>

        <!-- Success Modal -->
        <div
            v-if="showModal"
            class="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50"
        >
            <div class="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full text-center">
                <h2 class="text-lg font-semibold mb-4">
                    Form Submitted Successfully!
                </h2>
                <button
                    class="bg-blue text-white py-2 px-4 rounded-lg font-medium mt-4"
                    @click="closeModal"
                >
                    Close
                </button>
            </div>
        </div>
    </div>
</template>
  
<script>
import bg1 from '@/assets/img/resource_bg.svg';
import bg2 from '@/assets/img/hero5.svg';

export default {
    name: 'ContactForm',
    data() {
        return {
            loading: false,
            bg1,
            bg2,
            isMobile: window.innerWidth <= 768,
            showModal: false,
            formData: {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                zipCode: '',
                designation: '',
                practiceName: '',
                contactMethod: '',
                privacyPolicyAgreement: false,
                // checkbox2: false,
                // checkbox3: false,
            },
            otherDesignation: '',
            errors: {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                zipCode: '',
                designation: '',
                practiceName: '',
                contactMethod: '',
                privacyPolicyAgreement: '',
            }
        };
    },
    watch: {
        'formData.designation'(newVal) {
            if (newVal !== 'Other') {
                this.errors.otherDesignation = '';
                this.otherDesignation = '';
            }
        }
    },
    mounted() {
        // Update `isMobile` on resize
        window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        toggleCheckbox(checkbox) {
            this.formData[checkbox] = !this.formData[checkbox];
        },
        handleSubmit() {
            if (!this.validateForm()) {
                return;
            }

            this.loading = true;
            // eslint-disable-next-line no-undef
            fetch(process.env.VUE_APP_SUBMIT_FORM_ENDPOINT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...this.formData,
                    designation: this.formData.designation === 'Other' ? this.otherDesignation : this.formData.designation,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    this.loading = false;

                    if (data.error) {
                        console.error('Error sending form:', data.error);
                        return;
                    }

                    this.resetForm();
                    this.showModal = true;
                })
                .catch((error) => {
                    this.loading = false;
                    console.error('Error sending form:', error)
                });
        },
        closeModal() {
            this.showModal = false;
        },
        handleResize() {
            this.isMobile = window.innerWidth <= 768;
        },
        validateForm() {
            // Validate form fields
            this.errors.firstName = this.formData.firstName ? '' : 'First Name is required';
            this.errors.lastName = this.formData.lastName ? '' : 'Last Name is required';

            if (!this.formData.email && !this.formData.phoneNumber) {
                this.errors.email = 'Please enter an email or a phone number';
                this.errors.phoneNumber = 'Please enter an email or a phone number';
            } else {
                this.errors.email = this.formData.email
                    ? this.validateEmail(this.formData.email) 
                        ? '' 
                        : 'Please enter a valid email' 
                    : '';
                this.errors.phoneNumber = !this.errors.email || this.formData.phoneNumber ? '' : this.errors.phoneNumber;
            }

            if (this.formData.designation === 'Other' && !this.otherDesignation) {
                this.errors.otherDesignation = 'Please specify your professional designation';
            } else {
                this.errors.otherDesignation = '';
            }

            this.errors.privacyPolicyAgreement = this.formData.privacyPolicyAgreement ? '' : 'This agreement is required';

            return Object.values(this.errors).every((error) => !error);
        },
        clearError(field) {
            this.errors[field] = '';
        },
        validateEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },

        resetForm() {
            this.formData = {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                zipCode: '',
                designation: '',
                practiceName: '',
                contactMethod: '',
                privacyPolicyAgreement: false,
                // checkbox2: false,
                // checkbox3: false,
            };
            this.otherDesignation = '';
            this.errors = {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                zipCode: '',
                designation: '',
                practiceName: '',
                contactMethod: '',
                privacyPolicyAgreement: '',
            };
        },

        scrollToSection(sectionId) {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });

                setTimeout(() => {
                    window.scrollBy(0, -20);
                }, 600);
            }
        }
    },
};
</script>

<style lang="css" scoped>

.box {
    width: 15px;
    height: 15px;
}

</style>

<style scoped>
/* Calculate padding based on aspect ratio; if your image is 16:9, use 56.25% */
.hero-section {
    width: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    /* padding-bottom: 56.25%;  */
    padding-bottom: 43.75%; 

    position: relative;
}

/* Inner container */
.text-content {
    position: absolute;
    top: 50%;
    left: 71%;
    transform: translate(-50%, -50%);
    text-align: left;
    font-size: calc(1.2vw + 1em); /* Responsive font size */
    line-height: 1.2;
    max-width: 80%;
}

.hero-h1 {
    font-size: calc(1vw + 0.4em); /* Responsive heading size */
    font-weight: semibold;
    margin-bottom: 0.5em;
}

.hero-p {
    font-size: calc(0.5vw + 0.3em); /* Responsive paragraph size */
    font-weight: light;
}

.home-down-arrow {
    position: absolute;
    bottom: 6%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(2vw + 1em); /* Responsive width */
    height: calc(2vw + 1em); /* Responsive height */
    cursor: pointer;
}
</style>