<template>
    <!-- hero section  -->
    <div
        v-if="isMobile"
        :class="['flex flex-col h-screen-80 pt-16 pb-12 px-6 md:px-8', 'bg-white bg-opacity-80 bg-cover bg-no-repeat bg-bottom bg-blend-overlay']"
        :style="{ backgroundImage: `url(${jengaMobileBg})` }"
    >
        <div class="flex flex-col text-darkblue text-center">
            <h1 class="text-3xl md:text-4xl font-semibold mb-4">
                Epstein-Barr virus positive post-<br />transplant lymphoproliferative <br />disease (EBV<span class="text-green">+</span> PTLD)
            </h1>
            <p class="text-base font-light md:text-xl">
                EBV+ PTLD is a life-threatening and fatal complication of transplantation, occurring due to impaired T-cell immunity<sup>1</sup>
            </p>
        </div>

        <div class="mt-auto flex w-full justify-center items-center" @click="scrollToSection('section2')">
            <img
                src="@/assets/img/down-arrow.svg"
                alt="Scroll Arrow"
                class="ml-2 w-12"
            />
        </div>
    </div>

    <div
        v-if="!isMobile"
        class="hero-section flex items-center justify-center text-darkblue relative bg-cover bg-no-repeat bg-left-bottom"
        :style="{ backgroundImage: `url(${jengaDesktopBg})` }"
    >
        <div class="text-content">
            <h1 class="hero-h1">
                Epstein-Barr virus positive post-<br />transplant lymphoproliferative <br />disease (EBV<span class="text-green">+</span> PTLD)
            </h1>
            <p class="hero-p">
                EBV+ PTLD is a life-threatening and fatal complication of transplantation, occurring due to impaired T-cell immunity<sup>1</sup>
            </p>
        </div>
        <img
            src="@/assets/img/down-arrow.svg"
            alt="Scroll Arrow"
            class="home-down-arrow"
            @click="scrollToSection('section2')"
        />
    </div>

    <!-- section 2 - mobile version  -->
    <!-- Section 2 -->
    <div id="section2" class="bg-blue w-full py-12 md:py-16 px-6 md:px-6">
        <div class="flex flex-col lg:flex-row container mx-auto items-center justify-between gap-4">
            <!-- Left Column (50%) -->
            <div class="text-white text-center lg:text-left">
                <h1 class="text-3xl md:text-4xl font-semibold mb-5 leading-tight">
                    EBV+ PTLD is a rare and <br />aggressive hematologic <br />malignancy that is fatal <br />if left untreated<sup>1,2</sup>
                </h1>
                <h2 class="text-2xl font-light">
                    4 Key facts about EBV<span class="text-green">+</span> PTLD
                </h2>
            </div>

            <!-- Right Column (50%) -->
            <!-- Mobile: Stack items, Full-width boxes -->
            <div class="flex-1 lg:max-w-2/3 grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
                <div class="p-4 md:p-8 rounded-3xl border text-center md:text-left lg:pr-16 flex flex-col">
                    <h1 class="text-green text-6xl md:text-7xl mb-5 font-bigShoulders">
                        1.
                    </h1>
                    <h5 class="text-white mb-4 md:mb-10">
                        EBV+ PTLD occurs due to impaired T-cell immunity post-transplantation<sup>3,4</sup>
                    </h5>
                    <router-link to="/pathogenesis">
                        <h5 class="text-white font-bold flex items-center justify-center md:justify-start whitespace-nowrap mt-auto">
                            <span class="hover-underline">Read more </span>
                            <img src="@/assets/img/Arrow_Right.svg" alt="Arrow" class="ml-2" />
                        </h5>
                    </router-link>
                </div>
                <div class="p-4 md:p-8 rounded-3xl border text-center md:text-left lg:pr-16 flex flex-col">
                    <h1 class="text-green text-6xl md:text-7xl mb-5 font-bigShoulders">
                        2.
                    </h1>
                    <h5 class="text-white mb-4 md:mb-10">
                        Recipients of SOT or HCT have an increased risk of EBV+ PTLD<sup>5</sup>
                    </h5>
                    <router-link to="/monitoring">
                        <h5 class="text-white font-bold flex items-center justify-center md:justify-start whitespace-nowrap mt-auto">
                            <span class="hover-underline">Read more </span>
                            <img src="@/assets/img/Arrow_Right.svg" alt="Arrow" class="ml-2" />
                        </h5>
                    </router-link>
                </div>
                <div class="p-4 md:p-8 rounded-3xl border text-center md:text-left lg:pr-16 flex flex-col">
                    <h1 class="text-green text-6xl md:text-7xl mb-5 font-bigShoulders">
                        3.
                    </h1>
                    <h5 class="text-white mb-4 md:mb-10">
                        EBV+ PTLD presents differently in each patient, but disease progression is typically rapid and aggressive<sup>5–8</sup>
                    </h5>
                    <router-link :to="{ path: '/monitoring', hash: '#symptom' }">
                        <h5 class="text-white font-bold flex items-center justify-center md:justify-start whitespace-nowrap mt-auto">
                            <span class="hover-underline">Read more </span>
                            <img src="@/assets/img/Arrow_Right.svg" alt="Arrow" class="ml-2" />
                        </h5>
                    </router-link>
                </div>
                <div class="p-4 md:p-8 rounded-3xl border text-center md:text-left lg:pr-16 flex flex-col">
                    <h1 class="text-green text-6xl md:text-7xl mb-5 font-bigShoulders">
                        4.
                    </h1>
                    <h5 class="text-white mb-4 md:mb-10">
                        There are no currently approved treatments indicated for EBV+ PTLD<sup>9</sup>
                    </h5>
                    <router-link to="/management">
                        <h5 class="text-white font-bold flex items-center justify-center md:justify-start whitespace-nowrap mt-auto">
                            <span class="hover-underline">Read more </span>
                            <img src="@/assets/img/Arrow_Right.svg" alt="Arrow" class="ml-2" />
                        </h5>
                    </router-link>
                </div>
            </div>
        </div>
    </div>

    <!-- section 3 -->
    <div class="bg-white w-full py-12 md:py-16 px-6 md:px-6">
        <div class="container mx-auto">
            <h1 class="text-4xl font-semibold text-darkblue mb-8 text-center">
                Overall survival (OS) is low in patients who have failed initial treatment
            </h1>

            <!-- Buttons -->
            <div class="flex mb-8 gap-4">
                <button 
                    class="w-1/3 py-3 font-semibold rounded-lg flex-1"
                    :class="{
                        'bg-blue text-white': selectedView === 'view1',
                        'bg-white text-blue inactive-border rounded-md': selectedView !== 'view1'
                    }"
                    @click="selectView('view1')"
                >
                    HCT
                </button>
                <button 
                    class="w-1/3 py-3 font-semibold rounded-lg flex-1"
                    :class="{
                        'bg-blue text-white': selectedView === 'view2',
                        'bg-white text-blue inactive-border rounded-md': selectedView !== 'view2'
                    }"
                    @click="selectView('view2')"
                >
                    SOT
                </button>
            </div>

            <div class="flex justify-center mb-8 text-green">
                <h2 v-if="selectedView === 'view1'" class="text-2xl font-light">
                    Overall survival in patients with relapsed or refractory EBV+ PTLD following HCT (N=81)*<sup>1</sup>
                </h2>
                <h2 v-if="selectedView === 'view2'" class="text-2xl font-light">
                    Overall survival in patients with relapsed or refractory EBV+ PTLD following SOT (N=86)*<sup>2</sup>
                </h2>
            </div>

            <!-- Display SVG based on selected button -->
            <div class="text-left md:px-40">
                <div v-if="selectedView === 'view1'">
                    <img
                        src="@/assets/img/home_graph_1.svg"
                        alt="Graph 1"
                        class="mx-auto"
                    />
                    <p v-if="selectedView === 'view1'" class="text-darkblue pt-8 text-md">
                        *OS from date of R/R to rituximab ± chemotherapy. OS is from the R/R date to the end of follow-up.<br /><br />
                        <span class="text-sm">Adapted from Socié G, et al. Bone Marrow Transplant 2024;59:52–58.</span>
                    </p>
                </div>
                <div v-else-if="selectedView === 'view2'">
                    <img
                        src="@/assets/img/home_graph_2.svg"
                        alt="Graph 2"
                        class="mx-auto"
                    />
                    <p v-if="selectedView === 'view2'" class="text-darkblue pt-8 text-md">
                        *OS for patients with EBV+ PTLD following SOT who fail rituximab plus CT (N=86) from rituximab plus CT failure date.<br /><br />
                        <span class="text-sm">Adapted from Socié G, et al. Bone Marrow Transplant 2024;59:52–58.</span>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <!-- section 4  -->
    <div class="bg-white w-full py-12 md:py-16 px-6 md:px-6">
        <div class="flex flex-col md:flex-row container mx-auto items-center text-center justify-center">
            <h1 class="text-5xl text-blue font-semibold">
                EBV<span class="text-green">+</span> PTLD requires <br />urgent attention
            </h1>
        </div>
    </div>

    <!-- section 5 -->
    <div class="bg-blue w-full py-12 md:py-16 pt-0 px-6 md:px-6">
        <div class="flex flex-col md:flex-row container mx-auto">
            <!-- Left Column (50%) -->
            <div class="w-full md:w-1/2 text-center mb-6">
                <figure>
                    <img src="@/assets/img/ebv_graphic.svg" alt="EBV Graphic" class="mx-auto" />
                    <figcaption class="text-xs text-white font-light text-center">
                        Viral rendering for illustrative purposes only
                    </figcaption>
                </figure>
            </div>

            <!-- Right Column (50%) -->
            <div class="w-full md:w-1/2 flex flex-col gap-10 text-white text-left">
                <h1 class="text-4xl font-semibold text-center md:text-left">
                    What is EBV?
                </h1>

                <div class="flex flex-col gap-4 font-light">
                    <p class="">
                        EBV is one of the most common viruses found in humans<sup>10</sup>
                    </p>

                    <ul class="pl-4 list-disc">
                        <li>A gamma-herpes virus that is transmitted orally (via infected saliva)<sup>10</sup></li>
                        <li>~90% of adults and 50% of children are infected globally<sup>10</sup></li>
                    </ul>

                    <p class="">
                        EBV primarily infects B-cells and subsequently establishes a life-long latent infection within memory B-cells that is kept under control by intact T-cell immunity<sup>3,10</sup>
                    </p>
                </div>

                <p class="font-light">
                    When T-cell control mechanisms are impaired due to immunosuppression or in immunocompromised conditions, viral reactivation or infection can result in EBV-driven cancers<sup>3,4</sup>
                </p>
            </div>
        </div>

        <!-- Accordion Section -->
        <div class="flex flex-col md:flex-row container mx-auto items-center justify-center mt-8">
            <div class="w-full">
                <div class="border rounded-lg p-5 md:px-12 cursor-pointer text-white flex justify-between" @click="toggleAccordion">
                    <h1 class="text-lg font-light">
                        EBV is subsequently associated with more than a dozen malignancies including the proliferative B-cell disorder PTLD<sup>11</sup>
                    </h1>
                    <img
                        src="@/assets/img/Chevron_Down.svg"
                        alt="Icon"
                        class="w-6 h-6 mr-5"
                        :class="[isAccordionOpen ? 'transform rotate-180' : '']"
                    />
                </div>
                <div v-if="isAccordionOpen" class="p-5 md:px-12">
                    <div class="flex flex-col md:flex-row gap-8">
                        <div class="w-full md:w-2/3 flex flex-col gap-4 text-white font-light">
                            <h1 class="text-3xl font-semibold text-center md:text-left">
                                EBV drives many heterogeneous lymphoproliferative diseases (LPDs), malignancies, and other conditions<sup>6,12–15</sup>
                            </h1>
                            <h3 class="text-green text-center md:text-left">
                                EBV+ Diseases
                            </h3>

                            <h5 class="font-light">
                                <span class="font-semibold">EBV+ PTLD</span> - LPDs occurring following transplantation<sup>6</sup>
                            </h5>
                            <h5 class="font-light">
                                <span class="font-semibold">EBV+ PID-LPD</span> - LPDs associated with inherited or congenital immune deficiencies (e.g., common variable immunodeficiency)<sup>12</sup>
                            </h5>
                            <h5 class="font-light">
                                <span class="font-semibold">EBV+ AID-LPD</span> - LPDs associated with acquired immune deficiencies (e.g., lymphomas associated with HIV infection)<sup>13</sup>
                            </h5>
                            <h5 class="font-light">
                                <span class="font-semibold">Chronic Active EBV or HLH</span> - Chronic EBV infection of T- and NK cell type, or B-cell LPDs<sup>13</sup>; HLH: hyperinflammatory syndrome<sup>14</sup>
                            </h5>
                            <h5 class="font-light">
                                <span class="font-semibold">EBV+ Sarcomas</span> - Rare soft tissue sarcomas usually diagnosed in immunocompromised patients (e.g., leiomyosarcoma)<sup>15</sup>
                            </h5>
                        </div>

                        <div class="w-full md:w-1/3 xl:pr-36">
                            <div class="bg-green rounded-lg text-white p-5 max-w-full">
                                <h4 class="mb-3">
                                    Common Features
                                </h4>
                                <ul class="list-disc font-light pl-4">
                                    <li>Decreased immune surveillance<sup>12</sup></li>
                                    <li>Increased susceptibility to EBV infection<sup>12</sup></li>
                                    <li>EBV reactivation with uncontrolled cell proliferation<sup>13</sup></li>
                                    <li>Development of lymphoproliferative diseases<sup>13</sup></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- section 6 -->
    <div class="gradient-bg w-full">
        <div class="flex flex-col md:flex-row md:ml-0 mx-auto container py-12 md:py-16">
            <!-- Left Graphic Column -->
            <div class="w-full md:w-2/5 py-6 md:py-0">
                <img src="@/assets/img/side_jenga.png" alt="EBV Graphic" class="mx-auto md:ml-0" />
            </div>

            <!-- Right Text Column -->
            <div class="w-full md:w-3/5 p-6 md:p-8 flex flex-col justify-center text-blue font-light">
                <h1 class="text-3xl md:text-5xl font-semibold mb-4 text-darkblue text-center md:text-left">
                    What is PTLD?
                </h1>
                <p class="text-lg md:text-xl my-6 md:mt-6 md:mb-0 text-center md:text-left">
                    PTLD, or post-transplant lymphoproliferative disease, refers to a family of diseases that occur following transplantation. The disease is characterized by rapidly proliferating B-cells, most commonly triggered by uncontrolled Epstein-Barr virus (EBV) infection.<sup>5</sup>
                </p>
                <p class="text-lg md:text-xl my-6 md:mt-6 md:mb-0 text-center md:text-left">
                    PTLD is a life-threatening complication of organ transplantation, occurring after SOT and HCT and usually within the first year post-transplant.<sup>5</sup>
                </p>
                <p class="text-lg md:text-xl my-6 md:mt-6 md:mb-8 text-center md:text-left">
                    A diagnosis of PTLD has a significant impact on the lives of transplant patients.
                </p>

                <!-- remove per feedback  -->
                <!-- <div class="bg-green text-white rounded-lg py-5 px-4 flex flex-col md:flex-row items-center text-center md:text-left">
                    <div class="flex justify-center md:justify-start mb-4 md:mb-0">
                        <img src="@/assets/img/quote-icon.svg" alt="Icon" class="w-16 h-16" />
                    </div>
                    <div class="font-bigShoulders text-center">
                        <h1 class="text-3xl font-normal">
                            PTLD has a significant impact on patients' quality of life and psychological state
                        </h1>
                        <p class="text-sm">
                            Dr Ran Reshef, sourced from agence-sparadrap.com/ebvptld/
                        </p>
                    </div>
                </div> -->
            </div>
        </div>
    </div>

    <!-- section 7 -->
    <div class="bg-blue w-full text-white flex justify-center items-center py-12 md:py-16 px-6 md:px-6">
        <div class="container mx-auto flex flex-col items-center text-center">
            <h1 class="text-4xl font-semibold mb-8 md:mb-16 leading-snug">
                Impaired T-cell immunity post-transplantation plays a significant role in the development of EBV<span class="text-green">+</span> PTLD<sup>3,4</sup>
            </h1>

            <router-link to="/pathogenesis">
                <button class="py-3 px-8 font-semibold rounded-lg bg-blue text-white flex items-center justify-center w-full md:w-auto border-2 md:px-36 cta-button">
                    Find Out More
                    <img src="@/assets/img/Arrow_Right.svg" class="ml-2 animated-icon" />
                </button>
            </router-link>
        </div>
    </div>


    <!-- section 8 - references - align left -->
    <div class="bg-white w-full align-center text-blue py-12 md:py-16 px-6 md:px-6">
        <div class="container mx-auto">
            <p class="mb-5">
                Abbreviations: AID, acquired immune deficiency; CT, chemotherapy; EBV, Epstein-Barr virus; FDA, Food and Drug Administration; HCT, hematopoietic cell transplant; HLH, hemophagocytic lymphohistiocytosis; LPD, lymphoproliferative diseases; PID, primary immunodeficiency; PTLD, post-transplant lymphoproliferative disease; R/R, relapsed/refractory; SOT, solid organ transplantation.
            </p>
            <h2 class="font-light text-green mb-5">
                References
            </h2>
            <ol class="list-decimal pl-5">
                <li>Socié G, et al. Bone Marrow Transplant 2024;59:52–58.</li>
                <li>Dharnidharka V, et al. HemaSphere. 2022;6(Abstract):997–998.</li>
                <li>Nijland ML, et al. Transplant Direct 2015;2(1):e48.</li>
                <li>Cohen JI, N Engl J Med 2000;343:481–492.</li>
                <li>Fujimoto A, et al. Cancers (Basel). 2020;12:328.</li>
                <li>Dierickx D, et al. N Engl J Med. 2018;378:549–562.</li>
                <li>Styczynski J and Giebel S EBMT Handbook 2019; Chapter 45.</li>
                <li>Abbas F, et al. World J Transplant 2020;10(2):29–46.</li>
                <li class="break-all">
                    NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®). B-Cell Lymphomas 2024. Available at: https://www.nccn.org/guidelines/guidelines-detail?category=1&id=1480. Accessed November 2024.
                </li>
                <li>Bednarska K, et al. Br J Haematol 2024;204:415–433.</li>
                <li>Crombie JL and LaCasce AS. Front Oncol. 2019;9:109.</li>
                <li>Herber M, et al. Leuk Lymphoma. 2020;61(2):274–284.</li>
                <li>Marques-Piubelli ML, et al. Pathology. 2020;52:40–52.</li>
                <li>Janka GE, et al. Hematology Am Soc Hematol Educ Program 2013;2013:605–611.</li>
                <li>Hussein K, et al. Eur J Cancer 2014;50:2417–2424.</li>
            </ol>
        </div>
    </div>
</template>

<script>
import jengaDesktopBg from '@/assets/img/PF_Tcell_jenga_v6-small.jpeg';
import jengaMobileBg from '@/assets/img/PF_Tcell_jenga_v6-small_mobile.jpeg';
  
export default {
    name: 'LandingPage',
    data() {
        return {
            selectedView: 'view1',
            isAccordionOpen: false,
            accordionItems: [
                { title: 'Accordion Item 1', content: 'Content for accordion item 1.' },
            ],
            jengaDesktopBg,
            jengaMobileBg,
            isMobile: window.innerWidth <= 768, // Detect initial screen size
        };
    },
    mounted() {
        // Update `isMobile` on resize
        window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        selectView(view) {
            this.selectedView = view;
        },
        toggleAccordion() {
            this.isAccordionOpen = !this.isAccordionOpen;
        },
        handleResize() {
            this.isMobile = window.innerWidth <= 768;
        },

        scrollToSection(sectionId) {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });

                setTimeout(() => {
                    window.scrollBy(0, -30);
                }, 600);
            }
        }
        
    },
};

</script>

<style lang="css">
.inactive-border {
    border: 2px solid #0D2E63;
    border-radius: 10px;
}

.cta-button {
    border: 1px solid white;
    border-radius: 10px;
}
</style>

<style lang="css">
.gradient-bg {
    background: radial-gradient(circle, #FBFBFB 41%, #CDD6DD 100%) !important;
}

.light-condensed {
    font-weight: 300 !important;
}

</style>

<style scoped>
/* Calculate padding based on aspect ratio; if your image is 16:9, use 56.25% */
.hero-section {
    /* padding-bottom: 56.25%;  */
    padding-bottom: 43.75%; 
}

/* Inner container */
.text-content {
    position: absolute;
    top: 56%;
    left: 62%;
    transform: translate(-50%, -50%);
    text-align: left;
    font-size: calc(1.2vw + 1em); /* Responsive font size */
    line-height: 1.2;
    max-width: 80%;
}

.hero-h1 {
    font-size: calc(1vw + 0.4em); /* Responsive heading size */
    font-weight: semibold;
    margin-bottom: 0.5em;
}

.hero-p {
    font-size: calc(0.6vw + 0.4em); /* Responsive paragraph size */
    font-weight: light;
}

.home-down-arrow {
    position: absolute;
    bottom: 6%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(2vw + 1em); /* Responsive width */
    height: calc(2vw + 1em); /* Responsive height */
    cursor: pointer;
}

.hover-underline {
    position: relative;
    display: inline-block;
}

.hover-underline::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px; /* Position the underline just below the text */
    width: 100%;
    height: 2px;
    background-color: currentColor;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease;
}

.hover-underline:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.cta-button:hover .animated-icon {
    transform: translateX(5px); /* Adjust the value for more or less movement */
    transition: transform 0.3s ease; /* Smooth animation */
}


</style>