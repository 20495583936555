<template>
    <div>
        <div
            v-if="isMobile"
            :class="['flex flex-col justify-center', isMobile ? 'gradient-bg' : 'bg-cover bg-no-repeat bg-left-bottom']"
            :style="!isMobile ? { backgroundImage: `url(${bg1})` } : {}"
        >
            <div class="container mx-auto flex flex-col items-center px-6 sm:px-12 md:px-96 ">
                <div class="p-6 md:p-8 flex flex-col text-darkblue mt-16 text-center">
                    <h1 class="text-3xl md:text-4xl font-semibold mb-4">
                        Onset of Epstein-Barr virus positive post-transplant lymphoproliferative disease <br />(EBV<span class="text-green">+</span> PTLD)
                    </h1>
                    <p class="text-base font-light md:text-xl ">
                        EBV+ PTLD occurs as a result of EBV reactivation or infection after solid organ transplant (SOT) or hematopoietic cell transplantation (HCT)<sup>1,2</sup>
                    </p>
                </div>
            </div>
            <img
                src="@/assets/img/hand_1.png"
                alt="Scroll Arrow"
                class="w-full mt-12"
            />
            <div class="mb-12">
                <div class="flex w-full justify-center items-center text-center" @click="scrollToSection('section2')">
                    <img
                        src="@/assets/img/down-arrow.svg"
                        alt="Scroll Arrow"
                        class="ml-2 w-12 pl-0"
                    />
                </div>
            </div>
        </div>

        <div
            v-if="!isMobile"
            class="hero-section flex items-center justify-center text-darkblue"
            :style="{ backgroundImage: `url(${bg1})` }"
        >
            <div class="text-content">
                <h1 class="hero-h1">
                    Onset of Epstein-Barr virus <br />positive post-transplant <br />lymphoproliferative disease <br />(EBV<span class="text-green">+</span> PTLD)
                </h1>
                <p class="hero-p">
                    EBV+ PTLD occurs as a result of EBV reactivation or infection after solid organ transplant (SOT) or hematopoietic cell transplantation (HCT)<sup>1,2</sup>
                </p>
            </div>
            <img
                src="@/assets/img/down-arrow.svg"
                alt="Scroll Arrow"
                class="home-down-arrow"
                @click="scrollToSection('section2')"
            />
        </div>



        <!-- section 2  -->
        <div id="section2" class="bg-blue w-full py-12 md:py-16 px-6 md:px-6">
            <div class="container flex flex-col md:flex-row mx-auto items-center">
                <!-- Left Column (50%) -->
                <div class="text-white w-full md:w-1/2 lg:pr-12 text-center md:text-left">
                    <h1 class="leading-tight mb-5 font-semibold">
                        Impaired T-cell immunity plays a significant role in the development of EBV+ PTLD<sup>1,2</sup>
                    </h1>

                    <p>
                        Immunosuppressive therapy administered to patients post-SOT or post-HCT, impairs normal T-cell immunity that keeps EBV infection in check. Without T-cell control, EBV is left free to reactivate which can lead to the development of EBV+ PTLD<sup>1,2</sup>
                    </p>
                </div>
  
                <!-- Right Column (50%) -->
                <div class="w-full md:w-1/2 flex flex-col justify-between">
                    <!-- Top Row with two equal columns -->
                    

                    <img
                        src="@/assets/img/side_jenga_2.png"
                        alt="Graph 1"
                        class="h-full"
                    />
                </div>
            </div>
        </div>

        <!-- section 3 -->
        <div class="bg-white w-full py-12 md:py-16 px-6 md:px-6">
            <div class="container mx-auto text-center">
                <h2 class="text-3xl font-semibold text-darkblue mb-8">
                    Immunosuppression for transplantation alters T-cell immunity<sup>1,2</sup>
                </h2>

                <!-- Buttons -->
                <div class="flex flex-col md:flex-row gap-4 mb-8">
                    <button 
                        class="flex-1 py-3 font-semibold rounded-lg"
                        :class="{
                            'bg-blue text-white': selectedView === 'view1',
                            'bg-white text-blue inactive-border rounded-md': selectedView !== 'view1'
                        }"
                        @click="selectView('view1')"
                    >
                        Before transplant (Intact T-cell immunity)
                    </button>
                    <button 
                        class="flex-1 py-3 font-semibold rounded-lg"
                        :class="{
                            'bg-blue text-white': selectedView === 'view2',
                            'bg-white text-blue inactive-border rounded-md': selectedView !== 'view2'
                        }"
                        @click="selectView('view2')"
                    >
                        Post-transplant (Impaired T-cell immunity)
                    </button>
                </div>

                <div class="flex justify-center mb-8 text-green ">
                    <h3 v-if="selectedView === 'view1'" class="font-light">
                        With impaired T-cell immunity, reactivated EBV can cause B-cells to transform and rapidly proliferate, causing a range of malignancies, such as EBV+ PTLD<sup>1,2</sup>
                    </h3>
                    <h3 v-if="selectedView === 'view2'" class="font-light">
                        T-cells keep latent EBV infection under control by killing infected B-cells<sup>1,2</sup>
                    </h3>
                </div>

                <!-- Display SVG based on selected button -->
                <div class="mt-8 text-left">
                    <div v-if="selectedView === 'view1'">
                        <img
                            src="@/assets/img/vein1.svg"
                            alt="Graph 1"
                            class="mx-auto"
                        />
                        <!-- 5 columns here  -->
                        <div class="flex flex-row flex-wrap justify-center items-center mt-8 gap-6">
                            <div class="flex items-center justify-center gap-2">
                                <img src="@/assets/img/artery_wall.png" alt="Icon 2" class="w-8 h-8" />

                                <span class="text-xs font-medium text-darkblue">Vessel</span>
                            </div>
                            <div class="flex items-center justify-center gap-2">
                                <img src="@/assets/img/cell1.svg" alt="Icon 2" class="w-8 h-8" />
                                <span class="text-xs font-medium text-darkblue">EBV-specific CD8+ cytotoxic T-cell</span>
                            </div>
                            <div class="flex items-center justify-center gap-2">
                                <img src="@/assets/img/cell2.svg" alt="Icon 3" class="w-8 h-8" />
                                <span class="text-xs font-medium text-darkblue">CD4+ helper T-cell</span>
                            </div>
                            <div class="flex items-center justify-center gap-2">
                                <img src="@/assets/img/cell3.svg" alt="Icon 4" class="w-8 h-8" />
                                <span class="text-xs font-medium text-darkblue">Latently infected B-cell</span>
                            </div>
                            <div class="flex items-center justify-center gap-2">
                                <img src="@/assets/img/cell4.svg" alt="Icon 5" class="w-8 h-8" />
                                <span class="text-xs font-medium text-darkblue">Re-activated B-cell</span>
                            </div>
                        </div>
                        <p class="pt-8 text-xs">
                            Adapted from Niland ML, et al. Transplant Direct 2015;2(1):e48 and Cohen JI, et al. N Eng J Med 2000;343:481–492.
                        </p>
                    </div>
                    <div v-if="selectedView === 'view2'">
                        <img
                            src="@/assets/img/vein2.svg"
                            alt="Graph 2"
                            class="mx-auto"
                        />
                        <!-- 5 columns here  -->
                        <div class="flex flex-row flex-wrap justify-center items-center mt-8 gap-6">
                            <div class="flex items-center justify-center gap-2">
                                <img src="@/assets/img/artery_wall.png" alt="Icon 2" class="w-8 h-8" />

                                <span class="text-xs font-medium text-darkblue">Vessel</span>
                            </div>
                            <div class="flex items-center justify-center gap-2">
                                <img src="@/assets/img/cell1.svg" alt="Icon 2" class="w-8 h-8" />
                                <span class="text-xs font-medium text-darkblue">EBV-specific CD8+ cytotoxic T-cell</span>
                            </div>
                            <div class="flex items-center justify-center gap-2">
                                <img src="@/assets/img/cell2.svg" alt="Icon 3" class="w-8 h-8" />
                                <span class="text-xs font-medium text-darkblue">CD4+ helper T-cell</span>
                            </div>
                            <div class="flex items-center justify-center gap-2">
                                <img src="@/assets/img/cell3.svg" alt="Icon 4" class="w-8 h-8" />
                                <span class="text-xs font-medium text-darkblue">Latently infected B-cell</span>
                            </div>
                            <div class="flex items-center justify-center gap-2">
                                <img src="@/assets/img/cell4.svg" alt="Icon 5" class="w-8 h-8" />
                                <span class="text-xs font-medium text-darkblue">Re-activated B-cell</span>
                            </div>
                        </div>
                        <p class="pt-8 text-xs">
                            Adapted from Niland ML, et al. Transplant Direct 2015;2(1):e48 and Cohen JI, et al. N Eng J Med 2000;343:481–492.
                        </p>
                    </div>
                </div>

                <!-- removed per feedback  -->
                <!-- <div class="flex w-full bg-green text-white rounded-lg px-5 mt-12">
                    <div class="w-1/8 pt-2">
                        <img
                            src="@/assets/img/quote-icon.svg"
                            alt="Icon"
                            class="w-16 h-16"
                        />
                    </div>
                    <div class="w-7/8 items-center justify-center text-center py-5">
                        <h1 class="font-normal leading-none font-bigShoulders">
                            PTLD that occurs following a stem cell transplant is directly related to the depthand duration of T-cell immunosuppression
                        </h1>
                        <p>Dr Christopher Fox, sourced from agence-sparadrap.com/ebvptld/</p>
                    </div>
                </div> -->
            </div>
        </div>

        <!-- section 2  -->
        <div v-if="!isMobile" class="w-full py-16  px-12 md:px-24 gradient-bg">
            <div class="container flex flex-col md:flex-row mx-auto items-center mb-12 gap-6">
                <!-- Left Column (50%) -->
                <!-- TODO5: need the image below to be centered on the left 50% column -->
                <div class="flex-1 flex justify-center items-center">
                    <div class="relative inline-block">
                        <div class="text-center">
                            <img
                                src="@/assets/img/t-cell-control.png"
                                alt="Graph 1"
                                class="mx-auto object-contain"
                                @click="openModal2"
                            />
                            <p class="text-sm pt-5">
                                Adapted from Nijland MN, et al. Transplant Direct 2015;2(1):e48.
                            </p>
                        </div>
                       
                        <img
                            src="@/assets/img/zoom-in.svg"
                            alt="Small Icon"
                            class="absolute top-2 right-2 w-6 h-6"
                        />
                    </div>
                    <div v-if="isModal2Open" class="modal-overlay" @click="closeModal2">
                        <div class="modal-background gradient-bg" @click.stop>
                            <div class="modal-content">
                                <img 
                                    :src="modal2ImageSrc" 
                                    alt="Zoomed Image" 
                                    class="zoomed-image" 
                                    :style="{ transform: `scale(${zoomLevel})` }" 
                                    @wheel.prevent="handleZoom"
                                />
                                <button class="close-button" @click="closeModal2">
                                    ✕
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
  
                <!-- Right Column (50%) -->
                <div class="text-blue flex-1">
                    <h1 class="items-center justify-center font-semibold leading-tight mb-5">
                        Without T-cell control, EBV-infected B-cells may hyperproliferate, leading to EBV+ PTLD<sup>1,2</sup>
                    </h1>

                    <h3 class="font-light text-md text-green mb-5">
                        How do T-cells control EBV infection?
                    </h3>

                    <p>Primary EBV infection usually occurs through exposure to saliva or other fluids of an infected individual and results in either asymptomatic infection or symptomatic mononucleosis<sup>1,2</sup></p>
                    <br />
                    <p>Following primary infection, EBV establishes a lifelong latent infection within memory B-cells at low levels which is kept under control by intact T-cell immunity<sup>1,2</sup></p>
                    <br />
                    <p>During intermittent phases of EBV reactivation, T-cells can detect EBV-specific antigens expressed on infected cells and mount a T-cell response, killing the infected B-cell and inhibiting further viral replication and activity<sup>1,2</sup></p>
                </div>
            </div>

            <div class="flex flex-col md:flex-row container mx-auto items-center justify-center">
                <!-- Single Accordion -->
                <div class="w-full">
                    <div 
                        class="border border-blue rounded-lg p-5 cursor-pointer text-blue flex justify-between" 
                        @click="toggleAccordion"
                    >
                        <h1 class="text-lg font-light">
                            T-cells are able to elicit a specific attack against EBV-infected B-cells
                        </h1>
                        <img
                            src="@/assets/img/chevron-down-blue.svg"
                            alt="Icon"
                            class="w-6 h-6 mr-5"
                            :class="[isAccordionOpen ? 'transform rotate-180' : '']"
                        />
                    </div>
                    <div v-if="isAccordionOpen" class="mt-2 "> 
                        <div class="flex flex-col md:flex-row pt-3 gap-4">
                            <div class="flex-1">
                                <h3 class="text-green font-light mb-8 leading-tight">
                                    EBV-specific T-cells become activated upon recognition of the HLA-EBV complex. This requires three-pronged binding of:<sup>3</sup>
                                </h3>
                                <ul class="list-disc pl-5 mb-4 text-blue">
                                    <li>EBV antigen + specific HLA allele (EBV-HLA complex)</li>
                                    <li>EBV-specific T-cell receptor</li>
                                </ul>
                                <p class="text-blue">
                                    This binding then activates the T-cell response and results in targeted EBV+ tumor cell lysis<sup>3</sup>
                                </p>
                            </div>
                            <div class="flex-1">
                                <h3 class="font-semibold mb-1 leading-tight text-darkblue text-center">
                                    T-cell specific vs. EBV epitope in HLA class I<sup>4</sup>
                                </h3>
                                <img
                                    src="@/assets/img/t-cell-accordian-graphic.png"
                                    alt="Graph 1"
                                    class="cursor-pointer object-contain"
                                    @click="openModal"
                                />
                                <p class="text-xs font-light">
                                    Adapted from Dendrou C, et al. Nat Rev Immunol 2018;18(5):325–339.
                                </p>
                            </div>
                            <div v-if="isModalOpen" class="modal-overlay" @click="closeModal">
                                <div class="modal-background gradient-bg" @click.stop>
                                    <div class="modal-content">
                                        <img 
                                            :src="modalImageSrc" 
                                            alt="Zoomed Image" 
                                            class="zoomed-image" 
                                            :style="{ transform: `scale(${zoomLevel})` }" 
                                            @wheel.prevent="handleZoom"
                                        />
                                        <button class="close-button" @click="closeModal">
                                            ✕
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- section 2  -->
        <div v-else-if="isMobile" class="w-full py-12 md:py-16 px-6 md:px-6 gradient-bg">
            <div class="flex flex-col md:flex-row mx-auto items-center mb-12 gap-6">
                <!-- Left Column (50%) -->
                <div class="text-blue flex-1">
                    <h1 class="items-center justify-center font-semibold leading-tight mb-5 text-center">
                        Without T-cell control, EBV-infected B-cells may hyperproliferate, leading to EBV+ PTLD<sup>1,2</sup>
                    </h1>

                    <h3 class="font-light text-md text-green mb-5 text-center">
                        How do T-cells control EBV infection?
                    </h3>

                    <p>Primary EBV infection usually occurs through exposure to saliva or other fluids of an infected individual and results in either asymptomatic infection or symptomatic mononucleosis<sup>1,2</sup></p>
                    <br />
                    <p>Following primary infection, EBV establishes a lifelong latent infection within memory B-cells at low levels which is kept under control by intact T-cell immunity<sup>1,2</sup></p>
                    <br />
                    <p>During intermittent phases of EBV reactivation, T-cells can detect EBV-specific antigens expressed on infected cells and mount a T-cell response, killing the infected B-cell and inhibiting further viral replication and activity<sup>1,2</sup></p>
                </div>
                    
                <!-- Right Column (50%) -->
                
                <div class="flex-1">
                    <img
                        src="@/assets/img/t-cell-control.png"
                        alt="Graph 1"
                        class="object-contain"
                    />
                    <p class="text-sm pt-5">
                        Adapted from Nijland MN, et al. Transplant Direct 2015;2(1):e48.
                    </p>
                </div>
            </div>

            <div class="flex flex-col md:flex-row container mx-auto items-center justify-center">
                <!-- Single Accordion -->
                <div class="w-full">
                    <div 
                        class="border border-blue rounded-lg p-5 cursor-pointer text-blue flex justify-between" 
                        @click="toggleAccordion"
                    >
                        <h1 class="text-lg font-light">
                            T-cells are able to elicit a specific attack against EBV-infected B-cells
                        </h1>
                        <img
                            src="@/assets/img/chevron-down-blue.svg"
                            alt="Icon"
                            class="w-6 h-6 mr-5"
                            :class="[isAccordionOpen ? 'transform rotate-180' : '']"
                        />
                    </div>
                    <div v-if="isAccordionOpen" class="p-4 mt-2 md:mx-12"> 
                        <div class="flex flex-col md:flex-row pt-3 gap-4">
                            <div class="flex-1">
                                <h3 class="text-green font-light mb-8 leading-tight">
                                    EBV-specific T-cells become activated upon recognition of the HLA-EBV complex. This requires three-pronged binding of:<sup>3</sup>
                                </h3>
                                <ul class="list-disc pl-5 mb-4 text-blue">
                                    <li>EBV antigen + specific HLA allele (EBV-HLA complex)</li>
                                    <li>EBV-specific T-cell receptor</li>
                                </ul>
                                <p>This binding then activates the T-cell response and results in targeted EBV+ tumor cell lysis<sup>3</sup></p>
                            </div>
                            <div class="flex-1">
                                <h3 class="font-light mb-8 leading-tight text-darkblue text-center">
                                    T-cell-specific vs. EBV epitope in HLA class I<sup>4</sup>
                                </h3>
                                <img
                                    src="@/assets/img/t-cell-accordian-graphic.png"
                                    alt="Graph 1"
                                    class="cursor-pointer pl-3 object-contain"
                                    @click="openModal"
                                />
                                <p class="text-xs font-light">
                                    Adapted from Dendrou C, et al. Nat Rev Immunol 2018;18(5):325–339.
                                </p>
                            </div>
                            <div v-if="isModalOpen" class="modal-overlay" @click="closeModal">
                                <div class="modal-background gradient-bg" @click.stop>
                                    <div class="modal-content">
                                        <img 
                                            :src="modalImageSrc" 
                                            alt="Zoomed Image" 
                                            class="zoomed-image" 
                                            :style="{ transform: `scale(${zoomLevel})` }" 
                                            @wheel.prevent="handleZoom"
                                        />
                                        <button class="close-button" @click="closeModal">
                                            ✕
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- section 7 -->
        <div class="bg-blue w-full text-white flex justify-center items-center py-12 md:py-16 px-6 md:px-6">
            <div class="container mx-auto flex flex-col items-center text-center">
                <h1 class="text-4xl mb-16 font-semibold">
                    Identification of patients at risk of EBV<span class="text-green">+</span> PTLD is important to help optimize treatment outcomes and reduce disease burden
                </h1>

                <router-link to="/monitoring">
                    <button 
                        class="py-3 px-8 font-semibold rounded-lg bg-blue text-white cta-button flex items-center justify-center px-72"
                    >
                        Find Out More
                        <img
                            src="@/assets/img/Arrow_Right.svg"
                            class="ml-5 animated-icon"
                        />
                    </button>
                </router-link>
            </div>
        </div>


        <!-- section 8 - references - align left -->
        <div class="bg-white w-full align-center py-12 md:py-16 px-6 md:px-6 text-blue">
            <div class="container mx-auto">
                <p class="mb-5">
                    Abbreviations: EBV, Epstein-Barr virus; HCT, hematopoietic cell transplant; HLA, human leukocyte antigen; PTLD, post-transplant lymphoproliferative disease; SOT, solid organ transplantation.
                </p>
                <h2 class="font-light text-green mb-5">
                    References
                </h2>
                <ol class="list-decimal pl-5">
                    <li>Nijland ML, et al. Transplant Direct 2015;2(1):e48.</li>
                    <li>Cohen JI, et al. N Engl J Med 2000;343:481–492.</li>
                    <li>Abbas AK, et al. Basic Immunology: Functions and Disorders of the Immune System. 6th Ed. Philadelphia, PA: Elsevier/Saunders; 2019.</li>
                    <li>Dendrou C, et al. Nat Rev Immunol 2018;18(5):325–339.</li>
                </ol>
            </div>
        </div>
    </div>
</template>
  
<script>
import bg1 from '@/assets/img/patho_bg.svg';
import bg2 from '@/assets/img/hero2.svg';
import tcellgraphic from '@/assets/img/t-cell-accordian-graphic.png';
import cellGraphic from '@/assets/img/t-cell-control.png';



export default {
    name: 'PathogenesisPage',
    data() {
        return {
            selectedView: 'view1',
            isAccordionOpen: false,
            accordionItems: [
                { title: 'Accordion Item 1', content: 'Content for accordion item 1.' },
            ],
            bg1,
            bg2,
            isMobile: window.innerWidth <= 768, // Detect initial screen size
            isModalOpen: false,
            isModal2Open: false,
            modalImageSrc: tcellgraphic,
            modal2ImageSrc: cellGraphic,
            zoomLevel: 1,
        };
    },
    mounted() {
        // Update `isMobile` on resize
        window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        selectView(view) {
            this.selectedView = view;
        },
        toggleAccordion() {
            this.isAccordionOpen = !this.isAccordionOpen;
        },
        handleResize() {
            this.isMobile = window.innerWidth <= 768;
        },
        openModal() {
            this.isModalOpen = true;
        },
        closeModal() {
            this.isModalOpen = false;
        },
        openModal2() {
            this.isModal2Open = true;
        },
        closeModal2() {
            this.isModal2Open = false;
        },
        handleZoom(event) {
            if (event.deltaY < 0) {
                this.zoomLevel = Math.min(this.zoomLevel + 0.1, 3); // Zoom in, max 3x
            } else {
                this.zoomLevel = Math.max(this.zoomLevel - 0.1, 1); // Zoom out, min 1x
            }
        },
        scrollToSection(sectionId) {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });

                setTimeout(() => {
                    window.scrollBy(0, -30);
                }, 600);
            }
        }
    }
};
</script>

<style scoped>
/* Calculate padding based on aspect ratio; if your image is 16:9, use 56.25% */
.hero-section {
    width: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    /* padding-bottom: 56.25%;  */
    padding-bottom: 43.75%; 

    position: relative;
}

/* Inner container */
.text-content {
    position: absolute;
    top: 60%;
    left: 57%;
    transform: translate(-50%, -50%);
    text-align: left;
    font-size: calc(1.2vw + 1em); /* Responsive font size */
    line-height: 1.2;
    max-width: 80%;
}

.hero-h1 {
    font-size: calc(1vw + 0.4em); /* Responsive heading size */
    font-weight: bold;
    margin-bottom: 0.5em;
}

.hero-p {
    font-size: calc(0.5vw + 0.3em); /* Responsive paragraph size */
    font-weight: light;
}

.home-down-arrow {
    position: absolute;
    bottom: 6%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(2vw + 1em); /* Responsive width */
    height: calc(2vw + 1em); /* Responsive height */
    cursor: pointer;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Gradient background box */
.modal-background {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    border-radius: 8px;
    max-width: 90%;
    max-height: 90%;
    overflow: hidden;
}

.modal-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.zoomed-image {
    max-width: 100%;
    max-height: 100%;
    cursor: zoom-in; /* Indicates zoom functionality */
    transition: transform 0.2s ease; /* Smooth zoom transition */
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    color: white;
    cursor: pointer;
}

.button-container {
    display: flex;
    flex-direction: row;
    gap: 1rem; /* Space between buttons */
}

@media (max-width: 768px) {
    .button-container {
        flex-direction: column; /* Stack buttons vertically */
        align-items: center;    /* Center-align buttons */
    }
    .button-container button {
        width: 100%;            /* Full width for mobile */
        max-width: 300px;       /* Optional max-width to limit button size */
    }
}

.hover-underline {
    position: relative;
    display: inline-block;
}

.hover-underline::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px; /* Position the underline just below the text */
    width: 100%;
    height: 2px;
    background-color: currentColor;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease;
}

.hover-underline:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.cta-button:hover .animated-icon {
    transform: translateX(5px); /* Adjust the value for more or less movement */
    transition: transform 0.3s ease; /* Smooth animation */
}


</style>